import { Button, Dialog, Divider, Icon, Intent, Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FormikProps } from "formik";
import { isEqual } from "lodash";
import pick from "lodash/pick";
import {
  ButtonsBloc,
  FGCustomPanel,
  FGEmpty,
  FGListen,
  FGMaskInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext,
  InlineButtonContainer,
  useDebouncedCallback
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  AdresseDtoFromJSON,
  FcbFormateurDetailDto,
  FcbFormateurDetailDtoFromJSON,
  FormateurApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AdresseDoublonErrorLink,
  CommuneSelect,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGPersonneCodePostal,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LocaliteSelect,
  NationaliteSelect,
  PhotoModifier,
  SmallFormGenerator,
  StyledError
} from "../../../../../components";
import FGCopyTextInput from "../../../../../components/formGenerator/FGCopyTextInput";
import { useBeIdCardContext, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useFetchPicture, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { belgianIdValidate } from "../../../../../utils/belgianIdValidate";
import { phoneRegex } from "../../../../../utils/phoneRegex";

export interface IFormateurSignaletiqueProps {
  baseData: any;
}

export const FormateurSignaletique: React.FunctionComponent<IFormateurSignaletiqueProps> = ({ baseData }) => {
  const { id: idFormateur, state } = useParams<{ id: string; state: string }>();
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurApi);
  const { card } = useBeIdCardContext();
  const genderMapping = React.useMemo(() => ({ M: "H", F: "F", V: "F", W: "F" }), []);
  const [mustLoadPicture, setMustLoadPicture] = React.useState(true);
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const { theme } = useTheme();

  const { data, loading, deleteItem, deleting, saveItem, saving, setData } = useCrudApi<FcbFormateurDetailDto>(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData, codeNationalite: baseData.nationalite, paysNaissance: baseData.codePaysNaissance };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname ?? baseData?.nom,
              prenom: card?.firstName ?? baseData?.prenom,
              dateNaissance: card?.dateOfBirthParsed ?? baseData?.dateNaissance,
              codeNationalite: baseData?.nationalite,
              registreNational: card?.nationalNumber ?? baseData?.registreNational,
              communeNaissance: card?.locationOfBirth,
              photo: card?.photoB64,
              codesexe: card?.gender ? genderMapping[card?.gender] : null,
              adresseDomicileFormateur: !!card
                ? AdresseDtoFromJSON({
                    adresse: card?.addressStreetAndNumber,
                    codePostalText: card?.addressZip
                  })
                : null
            };
          }
          return +idFormateur <= 0
            ? FcbFormateurDetailDtoFromJSON({
                ...newData,
                idformateur: 0
              })
            : api.formateurGet({ id: +idFormateur });
        },
        saveApiFn: d => api.formateurSave({ FcbFormateurDetailDto: d }),
        deleteApiFn: d => api.formateurDelete({ id: d.idformateur }),
        onDeletedRoute: () => ERoutes.formateur,
        onSaved: () => setMustLoadPicture(true),
        onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/signaletique/edit`
      }),
      [api, baseData, card, genderMapping, idFormateur]
    )
  );

  const [photoDialogOpen, setPhotoDialogOpen] = React.useState<boolean>(false);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());
  const [pays, pLoading] = useReferential(a => a.referentialGetPays());
  const [langues, lLoading] = useReferential(a => a.referentialGetLangues(), true);

  const [picture, picLoading] = useFetchPicture(data?.idpersonne, [data]);
  React.useEffect(() => {
    if (picture && data && !cardDataDifferent && mustLoadPicture) {
      setMustLoadPicture(false);
      setData({ ...data, photo: picture });
    }
  }, [cardDataDifferent, data, mustLoadPicture, picture, setData]);

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.FormateurIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  const canUseCardData = React.useMemo(() => {
    if (+idFormateur > 0) {
      return !data?.registreNational || data?.registreNational === card?.nationalNumber;
    }

    return false;
  }, [card?.nationalNumber, data?.registreNational, idFormateur]);

  React.useEffect(() => {
    if (!picLoading && data && card) {
      const cardObj = FcbFormateurDetailDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber,
        communeNaissance: card?.locationOfBirth,
        photo: card?.photoB64,
        codesexe: card?.gender ? genderMapping[card?.gender] : null,
        adresse: card?.addressStreetAndNumber,
        codePostal: card?.addressZip
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational", "communeNaissance", "codesexe"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        !isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) ||
        cardBirthdateString !== dataBirthdateString
      ) {
        if (canUseCardData) {
          askDataDifferent();
        }
      }
    }
  }, [askDataDifferent, canUseCardData, card, data, genderMapping, picLoading, t]);

  const formikRef = React.useRef<FormikProps<FcbFormateurDetailDto>>();

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      formikRef?.current?.setValues(
        FcbFormateurDetailDtoFromJSON({
          ...data,
          nom: card?.surname ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          registreNational: card?.nationalNumber ?? data.registreNational,
          nationalite: data.codeNationalite ?? "BE",
          communeNaissance: card?.locationOfBirth ?? data.communeNaissance,
          photo: card?.photoB64 ?? data.photo,
          codesexe: card?.gender ? genderMapping[card?.gender] : data.codesexe,
          adresseFormateur: AdresseDtoFromJSON({
            ...data.adresseFormateur,
            adresse: card?.addressStreetAndNumber ?? data.adresseFormateur?.adresse,
            codePostalText: card?.addressZip ?? data.adresseFormateur?.codePostal
          })
        }),
        true
      );
    }
  }, [
    card?.addressStreetAndNumber,
    card?.addressZip,
    card?.cardNumber,
    card?.dateOfBirthParsed,
    card?.firstName,
    card?.gender,
    card?.locationOfBirth,
    card?.nationalNumber,
    card?.photoB64,
    card?.surname,
    cardDataDifferent,
    data,
    genderMapping,
    saveItem,
    formikRef,
    card?.nationality
  ]);

  const [hasDoublons, setHasDoublons] = React.useState(false);
  const checkDoublons = React.useCallback(
    async values => {
      const res = await api.formateurCheckDoublons({ FcbFormateurDto: values });
      setHasDoublons(!res.isValid);
    },
    [api]
  );
  const debouncedCheckDoublons = useDebouncedCallback(checkDoublons, 1000);

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.codeNationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.codeNationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        nom: Yup.string()
          .required(t(ETLCodes.Required))
          .max(100, t(ETLCodes.MaxCharacterApprenantNom)),
        prenom: Yup.string()
          .required(t(ETLCodes.Required))
          .max(100, t(ETLCodes.MaxCharacterApprenantPrenom)),
        codesexe: Yup.string().required(t(ETLCodes.Required)),
        dateNaissance: Yup.date().nullable(),
        registreNational: Yup.string()
          .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
            if (!value || value === "") return true;

            var millenial = false;
            if (this.parent.dateNaissance) {
              const parsedDateNaissance = new Date(this.parent.dateNaissance);
              if (parsedDateNaissance.getFullYear() > 1999) {
                millenial = true;
              }
            }
            return belgianIdValidate(value, millenial);
          })
          .when(["codeNationalite", "numeroIdentification"], {
            is: (codeNationalite, numeroIdentification) => codeNationalite === "BE" || !numeroIdentification,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        numeroIdentification: Yup.string()
          .nullable()
          .when(["codeNationalite", "registreNational"], {
            is: (codeNationalite, registreNational) => codeNationalite !== "BE" && !registreNational,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        telephoneFormateur: Yup.object().test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
          if (this.parent.idformateur > 0 || !value?.numero) return true;
          const numero = value?.numero as string;
          return !!numero.match(phoneRegex);
        }),
        gsmFormateur: Yup.object().test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
          if (this.parent.idformateur > 0 || !value?.numero) return true;
          const numero = value?.numero as string;
          return !!numero.match(phoneRegex);
        }),
        emailFormateur: Yup.object().when("idformateur", {
          is: idformateur => idformateur <= 0,
          then: Yup.object().shape({
            adresseEmail: Yup.string().email(t(ETLCodes.InvalidEmail))
          })
        })
      },
      [
        ["numeroIdentification", "codeNationalite"],
        ["numeroIdentification", "registreNational"],
        ["registreNational", "codeNationalite"],
        ["registreNational", "numeroIdentification"]
      ]
    );
  }, [t]);

  const isFullCreation = React.useMemo(() => +idFormateur <= 0 && !data?.idpersonne, [data?.idpersonne, idFormateur]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.formateur)}
      saving={saving}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idFormateur > 0}
      formikInnerRef={i => (formikRef.current = i)}
      forceEnableSave={Object.values(baseData).some(el => !!el)}
    >
      <FGListen field="nom" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
      <FGListen field="prenom" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
      <FGListen field="dateNaissance" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
      <FieldGroup columns={2}>
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGWalterSelectInput name="codesexe" label={t(ETLCodes.Genre)} items={sexe} loading={sLoading} />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGTextInput name="prenom2" label={t(ETLCodes.SecondPrenom)} maxLength={50} />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="gsmFormateur.numero"
              label={t(ETLCodes.Gsm)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGEditableTelSelectInput
              name="gsmFormateur.idtelephone"
              label={t(ETLCodes.Gsm)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["GSM_PERSO"]}
            />
          )}
          <FGWalterSelectInput name="paysNaissance" label={t(ETLCodes.PaysNaissance)} items={pays} loading={pLoading} />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="gsmProfFormateur.numero"
              label={t(ETLCodes.GsmPro)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGEditableTelSelectInput
              name="gsmProfFormateur.idtelephone"
              label={t(ETLCodes.GsmPro)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["GSM_PRO"]}
            />
          )}
          <FGTextInput name="localiteNaissance" label={t(ETLCodes.LocaliteNaissance)} maxLength={100} />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="telephoneFormateur.numero"
              label={t(ETLCodes.Tel)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGEditableTelSelectInput
              name="telephoneFormateur.idtelephone"
              label={t(ETLCodes.Tel)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["PERSO"]}
            />
          )}
          <FGTextInput name="communeNaissance" label={t(ETLCodes.CommuneNaissance)} maxLength={100} />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="telephoneProfFormateur.numero"
              label={t(ETLCodes.TelPro)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGEditableTelSelectInput
              name="telephoneProfFormateur.idtelephone"
              label={t(ETLCodes.TelPro)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["PRO"]}
            />
          )}
          <NationaliteSelect
            name="codeNationalite"
            codeSexeFieldName="codesexe"
            helperText={card && `${t(ETLCodes.NationaliteSurCarte)}: ${card?.nationality}`}
          />
          {isFullCreation ? (
            <FGTextInput
              name="emailFormateur.adresseEmail"
              label={t(ETLCodes.General_Email)}
              maxLength={100}
              visible={isFullCreation}
            />
          ) : (
            <FGEditableEmailSelectInput
              name="emailFormateur.idemail"
              label={t(ETLCodes.General_Email)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
            />
          )}
          <FGMaskInput
            name="registreNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
            helperText={ctx => (
              <>
                {isRegNatDateNaiss(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                )}
                {isRegNatGenre(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                )}
              </>
            )}
          />
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.codeNationalite && ctx.formik?.values?.codeNationalite !== "BE"}
          />
          <FGTextInput name="azureEmail" label={t(ETLCodes.EmailIfapme)} readonly />
          <FGTextInput name="azureTemporaryPassword" label={t(ETLCodes.TemporaryPassword)} readonly />
        </FieldGroup>
        <FieldGroup columns={[3, 4, 5]}>
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {ctx.formik.values.photo ? (
                  <img
                    src={`data:image/png;base64,${ctx.formik.values.photo}`}
                    alt="Profil"
                    style={{ maxHeight: "9.5rem" }}
                  />
                ) : (
                  <Icon iconSize={136.52} icon={IconNames.USER} style={{ marginBottom: "10px" }} />
                )}
              </div>
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer style={{ marginBottom: "10px" }}>
                <ButtonsBloc />
                <ButtonsBloc>
                  <Button
                    icon={IconNames.CAMERA}
                    text={t(ETLCodes.ModifierPhoto)}
                    onClick={() => setPhotoDialogOpen(true)}
                    disabled={!ctx.editMode}
                  />
                  <Button
                    icon={IconNames.DELETE}
                    minimal={true}
                    intent={Intent.DANGER}
                    onClick={() => ctx.formik.setFieldValue("photo", null)}
                    disabled={!ctx.editMode}
                  />
                </ButtonsBloc>
                <ButtonsBloc />
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
      <Divider />

      <FieldGroup columns={4}>
        <FieldGroup>
          <FieldGroup columns={2}>
            <Label style={{ marginBottom: 0 }}>{t(ETLCodes.AdresseDomicile)}</Label>
          </FieldGroup>
          {data?.hasAddressDomicileDoublons && (
            <>
              <AdresseDoublonErrorLink idpersonne={idpersonne} />
              <FGEmpty />
            </>
          )}
          <FGWalterCheckboxInput
            name="courrierDomicile"
            label={t(ETLCodes.EnvoiACetteAdresse)}
            disabled={data?.hasAddressDomicileDoublons}
          />
          <FGEmpty />
          <FGWalterSelectInput
            name="adresseDomicileFormateur.pays"
            label={t(ETLCodes.Pays)}
            items={pays}
            loading={pLoading}
            disabled={data?.hasAddressDomicileDoublons}
          />
          <FGEmpty />
          <FGTextAreaInput
            name="adresseDomicileFormateur.adresse"
            label={t(ETLCodes.Adresse)}
            maxLength={100}
            disabled={data?.hasAddressDomicileDoublons}
          />
          <FGEmpty />
          <FGTextInput
            name="adresseDomicileFormateur.boite"
            label={t(ETLCodes.Boite)}
            maxLength={5}
            disabled={data?.hasAddressDomicileDoublons}
          />
          <FGPersonneCodePostal
            idName="adresseDomicileFormateur.idcodePostal"
            textName="adresseDomicileFormateur.codePostalText"
            localiteName="adresseDomicileFormateur.localite"
            disabled={data?.hasAddressDomicileDoublons}
          />
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <LocaliteSelect
                codePostal={ctx.formik.values?.adresseDomicileFormateur?.codePostalText}
                codePays={ctx.formik.values?.adresseDomicileFormateur?.pays}
                name="adresseDomicileFormateur.localite"
                codePostalTextName="adresseDomicileFormateur.codePostalText"
                disableIfNoCp={false}
                disabled={data?.hasAddressDomicileDoublons}
              />
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <CommuneSelect
                codePostal={ctx.formik.values?.adresseDomicileFormateur?.codePostalText}
                codePays={ctx.formik.values?.adresseDomicileFormateur?.pays}
                name="adresseDomicileFormateur.commune"
                disabled={data?.hasAddressDomicileDoublons}
              />
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGWalterCheckboxInput name="frontalier" label={t(ETLCodes.Frontalier)} />
        </FieldGroup>

        <FieldGroup>
          <FieldGroup columns={2}>
            <Label style={{ marginBottom: 0 }}>{t(ETLCodes.AdresseAutre)}</Label>
          </FieldGroup>
          {data?.hasAddressDoublons && (
            <>
              <AdresseDoublonErrorLink idpersonne={idpersonne} />
              <FGEmpty />
            </>
          )}
          <FGWalterCheckboxInput
            name="courrierAutre"
            label={t(ETLCodes.EnvoiACetteAdresse)}
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGWalterSelectInput
            name="adresseFormateur.pays"
            label={t(ETLCodes.Pays)}
            items={pays}
            loading={pLoading}
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGTextAreaInput
            name="adresseFormateur.adresse"
            label={t(ETLCodes.Adresse)}
            maxLength={100}
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGPersonneCodePostal
            idName="adresseFormateur.idcodePostal"
            textName="adresseFormateur.codePostalText"
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGEmpty />
          <FGWalterSelectInput name="idcodeLangue" label={t(ETLCodes.Langue)} items={langues} loading={lLoading} />
        </FieldGroup>
      </FieldGroup>
      <FGCustomPanel>
        {ctx => <StyledError show={hasDoublons} message={t(ETLCodes.DoublonExistsConfirm)}></StyledError>}
      </FGCustomPanel>
      <FGCustomPanel>
        {ctx => (
          <Dialog
            isOpen={photoDialogOpen}
            onClose={() => setPhotoDialogOpen(false)}
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
            title={`Photo ${ctx.formik.values?.nom} ${ctx.formik.values?.prenom}`}
            style={{ width: "80%", height: "500px" }}
            enforceFocus={false}
          >
            <PhotoModifier
              photo={ctx.formik.values?.photo}
              onSave={(newPhoto: any) => {
                ctx.formik.setFieldValue("photo", newPhoto);
                setPhotoDialogOpen(false);
              }}
              onCancel={() => setPhotoDialogOpen(false)}
            />
          </Dialog>
        )}
      </FGCustomPanel>
    </SmallFormGenerator>
  );
};
