import { Alignment, Button, Classes, Intent, Menu, MenuItem, Navbar, Popover } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PrintingQueue } from ".";
import { CarteIdentiteApi } from "../api";
import { ERoutes } from "../AppRouter";
import assets from "../assets";
import { useBeIdCardContext, useBreadcrumbs, useGlobalData } from "../contexts";
import { useAuth } from "../contexts/AuthContext";
import { useApiService, useTheme, useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { SiteTheme } from "../theme";
import { exportFile } from "../utils";
import { AppBreadcrumb } from "./AppBreadcrumb";

interface IHeaderProps {
  showMenuIcon: boolean;
  toggleLeftMenu?: () => void;
  toggleExportMenu?: () => void;
  toggleReportMenu?: () => void;
  isDark?: boolean;
  onDarkSwitchChange?: (e: boolean) => void;
}

const NavBarStyled = styled(Navbar)<{ theme: SiteTheme }>`
  background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.headerLeftColor},
      ${({ theme }) => theme.headerLeftColor} 25%,
      rgba(255, 255, 255, 0.5),
      white 85%,
      ${({ theme }) => theme.headerRightColor} 90%
    ),
    url(${assets.images.headerImage}) !important;
  background-position: 0% 35%;
  height: 75px !important;

  & > .${Classes.NAVBAR_GROUP} {
    height: 75px;
  }

  & .${Classes.ICON} {
    ${props => (props.theme.siteTheme === "hope" ? "color: white !important" : "")}
  }

  & .impersonatorText {
    font-size: smaller;
    font-style: italic;
  }
`;

const IfapmeImage = styled.img`
  height: 75px;
`;

const ButtonsLogo = styled.img`
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
`;

const WalterImage = styled.img<{ color: string }>`
  &.walter {
    margin-top: 16px;
    height: 80px;
  }

  &.hope {
    height: 50px;
    filter: drop-shadow(1px 1px 3px #cecece);
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export const Header: React.FC<IHeaderProps> = props => {
  const history = useHistory();
  const { logout, user } = useAuth();
  const api = useApiService(CarteIdentiteApi);
  const { t } = useTl();
  const { theme, ifapmeSide } = useTheme();
  const {
    arWalterManuelUrl,
    arHopeManuelUrl,
    manuelUrl,
    priseEnMainUrl,
    printingQueueWalterManuelUrl
  } = useGlobalData();

  const { breadcrumbs } = useBreadcrumbs();
  const { connected, newVersionAvailable } = useBeIdCardContext();

  const downloadBeIdSetup = React.useCallback(async () => {
    const file = await api.carteIdentiteDownload();
    await exportFile(file);
  }, [api]);

  const userFullName = React.useMemo(() => {
    if (!user) return "";
    return user.prenom + " " + user.nom;
  }, [user]);

  const impersonatorUser = React.useMemo(() => {
    if (!user?.impersonator) return null;
    return user.impersonator.prenom + " " + user.impersonator.nom;
  }, [user?.impersonator]);

  return (
    <div className={Classes.DARK}>
      <NavBarStyled theme={theme}>
        <Navbar.Group align={Alignment.LEFT}>
          {props.toggleLeftMenu && props.showMenuIcon && (
            <>
              <Button icon={IconNames.MENU} minimal={true} onClick={props.toggleLeftMenu} />
              <Navbar.Divider />
            </>
          )}
          <div style={{ minWidth: 224, textAlign: "center" }}>
            <WalterImage src={theme.logoUrl} className={ifapmeSide} color={theme.headerRightColor} alt="logo" />
          </div>
          <Navbar.Divider />
          <Navbar.Heading
            style={{
              cursor: "pointer",
              fontSize: "1.3rem"
            }}
            onClick={() => {
              history.push(ERoutes.home);
            }}
          ></Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <div style={{ marginLeft: 0 }}>
              <AppBreadcrumb items={breadcrumbs}></AppBreadcrumb>
            </div>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <IfapmeImage src={assets.images.ifapmeLogo} alt="IFAPME" />
          {connected ? (
            <Popover
              inheritDarkTheme={false}
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      icon={IconNames.ID_NUMBER}
                      text={t(ETLCodes.CarteIdentite)}
                      intent={Intent.PRIMARY}
                      onClick={() => history.push(ERoutes.beidReader)}
                    />
                    <MenuItem
                      icon={IconNames.DOWNLOAD}
                      text={t(newVersionAvailable ? ETLCodes.DownloadBeidNew : ETLCodes.DownloadBeid)}
                      intent={Intent.PRIMARY}
                      onClick={downloadBeIdSetup}
                    />
                  </Menu>
                </PopoverContent>
              }
              interactionKind="hover"
            >
              <ButtonsLogo
                src={newVersionAvailable ? assets.images.newVersionBeidLogo : assets.images.onbeidLogo}
                style={{ cursor: "pointer" }}
                alt="onBEID"
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          ) : (
            <Popover
              content={
                <div style={{ padding: "10px", cursor: "pointer" }}>{t(ETLCodes.NotConnectedToBEIDClikToDownload)}</div>
              }
              interactionKind="hover"
            >
              <ButtonsLogo src={assets.images.nobeidLogo} alt="noBEID" onClick={downloadBeIdSetup} />
            </Popover>
          )}
          {/* <Popover
            content={<div style={{ padding: "10px", cursor: "pointer" }}>{t(ETLCodes.ExportExcel)}</div>}
            interactionKind="hover"
          > */}
          {props.toggleExportMenu && (
            <ButtonsLogo
              style={{ cursor: "pointer" }}
              src={assets.images.excel}
              alt="excelExport"
              onClick={props.toggleExportMenu}
            />
          )}
          {/* </Popover> */}
          {/* <Popover
            content={<div style={{ padding: "10px", cursor: "pointer" }}>{t(ETLCodes.AllReports)}</div>}
            interactionKind="hover"
          > */}
          {props.toggleReportMenu && (
            <ButtonsLogo
              style={{ cursor: "pointer" }}
              src={assets.images.rapport}
              alt="report"
              onClick={props.toggleReportMenu}
            />
          )}
          {/* {devMode && <Button icon="style" minimal={true} onClick={() => toggleDevTheme()}></Button>} */}
          {/* </Popover> */}
          <PrintingQueue />
          {(!!manuelUrl || !!priseEnMainUrl) && ifapmeSide === "hope" && (
            <Popover
              inheritDarkTheme={false}
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      text={t(ETLCodes.Manuel)}
                      intent={Intent.PRIMARY}
                      onClick={() => window.open(manuelUrl, "_blank")}
                      disabled={!manuelUrl}
                      icon="download"
                    />
                    <MenuItem
                      text={t(ETLCodes.PriseEnMain)}
                      intent={Intent.PRIMARY}
                      onClick={() => window.open(priseEnMainUrl, "_blank")}
                      disabled={!priseEnMainUrl}
                      icon="download"
                    />
                    <MenuItem
                      text={t(ETLCodes.ManuelAvisResultats)}
                      intent={Intent.PRIMARY}
                      onClick={() => window.open(arHopeManuelUrl, "_blank")}
                      disabled={!arHopeManuelUrl}
                      icon="download"
                    />
                  </Menu>
                </PopoverContent>
              }
              interactionKind="hover"
            >
              <Button
                minimal={true}
                icon="help"
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          )}
          {ifapmeSide === "walter" && (
            <Popover
              inheritDarkTheme={false}
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      text={t(ETLCodes.ManuelAvisResultats)}
                      intent={Intent.PRIMARY}
                      onClick={() => window.open(arWalterManuelUrl, "_blank")}
                      disabled={!arWalterManuelUrl}
                      icon="download"
                    />
                    <MenuItem
                      text={t(ETLCodes.ManuelGenerationRapport)}
                      intent={Intent.PRIMARY}
                      onClick={() => window.open(printingQueueWalterManuelUrl, "_blank")}
                      disabled={!printingQueueWalterManuelUrl}
                      icon="download"
                    />
                  </Menu>
                </PopoverContent>
              }
              interactionKind="hover"
            >
              <Button
                minimal={true}
                icon="help"
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          )}
          <Popover
            content={
              <Menu>
                <MenuItem text={t(ETLCodes.Logout)} icon={IconNames.LOG_OUT} onClick={() => logout()}></MenuItem>
              </Menu>
            }
          >
            <Button
              icon="user"
              text={
                <div>
                  <div>{userFullName}</div>
                  {impersonatorUser && (
                    <div className="impersonatorText">
                      ({t(ETLCodes.OnBehalfOf)} {impersonatorUser})
                    </div>
                  )}
                </div>
              }
              minimal={true}
            ></Button>
          </Popover>
        </Navbar.Group>
      </NavBarStyled>
    </div>
  );
};
