import { Divider } from "@blueprintjs/core";
import {
  FGCheckboxInput,
  FGListen,
  FGTextAreaInput,
  FieldGroup,
  FieldSet,
  showError,
  useApiEffect
} from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { InscriptionDateConvocationSelector } from "../..";
import { EIfapmeSide, FcbInscriptionMasseDto, InscriptionMasseApi, MetierApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  PageBase,
  ProgressBarDialog,
  SmallFormGenerator,
  StyledError
} from "../../../../components";
import { FGEuroMaskInput } from "../../../../components/formGenerator/FGEuroMaskInput";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { InscriptionMasseTable } from "./InscriptionMasseTable";

export interface IInscriptionMasseItemPageProps {}

export const InscriptionMasseItemPage: React.FunctionComponent<IInscriptionMasseItemPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(InscriptionMasseApi);
  const metierApi = useApiService(MetierApi);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        idstade: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idcentre: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        anneeScolaire: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required)),
        typecours: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required)),
        idmetier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idclasse: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        degre: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateConvocation: Yup.date()
          .nullable()
          .when("dateConvocationString", (value, schema) => (!value ? schema.required(t(ETLCodes.Required)) : schema)),
        dateConvocationString: Yup.string()
          .nullable()
          .when("dateConvocation", (value, schema) => (!value ? schema.required(t(ETLCodes.Required)) : schema)),
        dateInscription: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required))
      },
      [["dateConvocation", "dateConvocationString"]]
    );
  }, [t]);

  const [idClasse, setIdClasse] = React.useState(0);
  const [idCentre, setIdCentre] = React.useState(0);
  const [anneeScolaire, setAnneeScolaire] = React.useState("");
  const [typeCours, setTypeCours] = React.useState("");
  const [idMetier, setIdMetier] = React.useState(0);

  const [apprenants, setApprenants] = React.useState<number[]>(null);

  const getClasseParams = React.useMemo(() => {
    return {
      anneeScolaire,
      idCentre,
      idMetier,
      typeCours
    };
  }, [anneeScolaire, idCentre, idMetier, typeCours]);

  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);
  const [statutInsccription, statInscLoading] = useReferential(a => a.referentialGetStatutInscription(), true, []);
  const [stade, stadeLoading] = useReferential(a => a.referentialGetStade(), true, []);
  const [metier, metierLoading] = useReferential(a => a.referentialGetMetier(), true, []);
  const { ifapmeSide } = useTheme();
  const [typeCoursList, tcLoading] = useReferential(
    a => a.referentialGetTypeCours({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [degre, degreLoading] = useReferential(a => a.referentialGetDegre(), false, []);
  const [anneeScolaireList, anneeScolaireLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);

  const [classes, claLoading] = useReferential(a => a.referentialGetClasse(getClasseParams), false, [getClasseParams]);

  const [codeMetier] = useApiEffect(() => metierApi.metierGetCodeMetier({ id: +idMetier }), [idMetier]);

  const [inscriptionsEngaged, setInscriptionsEngaged] = React.useState(false);
  const [currentInscriptionIndex, setCurrentInscriptionIndex] = React.useState(0);
  const [lastInscriptionIndex, setLastInscriptionIndex] = React.useState(0);

  const engageSaveOnByOne = React.useCallback(
    async (d: FcbInscriptionMasseDto) => {
      setInscriptionsEngaged(true);
      setLastInscriptionIndex(d.idspprenant.length);
      for (let i = 0; i < d.idspprenant.length; i++) {
        setCurrentInscriptionIndex(i);

        try {
          await api.inscriptionMasseSaveOneByOne({ FcbInscriptionMasseDto: { ...d, idspprenant: [d.idspprenant[i]] } });
        } catch (e) {
          showError(t(ETLCodes.ErrorWhileInscriptionMasse, { id: d.idspprenant[i] }));
        }
      }
      setCurrentInscriptionIndex(d.idspprenant.length);
      return d;
    },
    [api, t]
  );

  const { data, saving, saveItem, setData } = useCrudApi({
    getApiFn: () => api.inscriptionMasseGetBaseData(),
    saveApiFn: d => engageSaveOnByOne(d),
    deleteApiFn: () => {}
  });

  const submitCallback = React.useCallback(
    async (values: FcbInscriptionMasseDto) => {
      await saveItem({ ...values, idspprenant: apprenants });
      // await apprenantApi.apprenantRefreshAnneeScolaireView();
      const a = { ...values, apprenants: [] };
      setData(a);
    },
    [apprenants, saveItem, setData]
  );

  const progressTitle = React.useMemo(
    () =>
      currentInscriptionIndex === lastInscriptionIndex
        ? t(ETLCodes.InscriptionDone)
        : t(ETLCodes.InscriptionInProgress),
    [currentInscriptionIndex, lastInscriptionIndex, t]
  );

  const displaySearchTable = React.useMemo(() => !!idClasse && !!anneeScolaire && !!idCentre && !!idMetier, [
    anneeScolaire,
    idCentre,
    idClasse,
    idMetier
  ]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.InscriptionsMasse), route: ERoutes.inscriptionMasse }]} withCard>
      <FieldSet title={t(ETLCodes.InscriptionsMasse)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={submitCallback}
            showColons
            editMode
            validationSchema={FormSchema}
            saving={saving}
            formatDate="dd-MM-yyyy"
            enableDirtyCheck={false}
          >
            <FGListen
              field="anneeScolaire"
              onChanged={(value, formik) => {
                setAnneeScolaire(value);
                if (formik.values.idclasse !== null) {
                  formik.setFieldValue("idclasse", null);
                }
              }}
            />
            <FGListen
              field="dateConvocationString"
              onChanged={(value, formik) => {
                value && formik.touched?.dateConvocationString && formik.setFieldValue("dateConvocation", undefined);
              }}
            />
            <FGListen
              field="dateConvocation"
              onChanged={(value, formik) => {
                value && formik.touched?.dateConvocation && formik.setFieldValue("dateConvocationString", undefined);
              }}
            />
            <FGListen
              field="idcentre"
              onChanged={(value, formik) => {
                setIdCentre(value);
                if (formik.values.idclasse !== null) {
                  formik.setFieldValue("idclasse", null);
                }
              }}
            />
            <FGListen
              field="typecours"
              onChanged={(value, formik) => {
                setTypeCours(value);
                if (formik.values.idclasse !== null) {
                  formik.setFieldValue("idclasse", null);
                }
              }}
            />
            <FGListen
              field="idmetier"
              onChanged={(value, formik) => {
                setIdMetier(value);
                if (formik.values.idclasse !== null) {
                  formik.setFieldValue("idclasse", null);
                }
              }}
            />
            <FGListen
              field="idclasse"
              onChanged={(value, formik) => {
                if (value) {
                  setIdClasse(value);
                }
                formik.setFieldValue("apprenants", []);
              }}
            />
            <FieldGroup columns={2}>
              <FGWalterSelectInput name="idstade" label={t(ETLCodes.Stade)} items={stade} loading={stadeLoading} />
              <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={cLoading} />
              <FGWalterSelectInput
                name="anneeScolaire"
                label={t(ETLCodes.AnneeScolaire)}
                items={anneeScolaireList}
                loading={anneeScolaireLoading}
              />
              <FGWalterSelectInput
                name="typecours"
                label={t(ETLCodes.TypeCours)}
                items={typeCoursList}
                loading={tcLoading}
              />
            </FieldGroup>
            <Divider />
            <FieldGroup columns={2}>
              <FGWalterSelectInput name="idmetier" label={t(ETLCodes.Metier)} items={metier} loading={metierLoading} />
              <FGWalterSelectInput name="degre" label={t(ETLCodes.Degre)} items={degre} loading={degreLoading} />
              <FGWalterSelectInput name="idclasse" label={t(ETLCodes.Classe)} items={classes} loading={claLoading} />
            </FieldGroup>
            <FieldGroup columns={2}>
              <FGWalterDateMaskInput name="dateInscription" label={t(ETLCodes.DateInscription)} />
              <InscriptionDateConvocationSelector />
              <FGWalterSelectInput
                name="idstatut"
                items={statutInsccription}
                disabled
                label={t(ETLCodes.Statut)}
                loading={statInscLoading}
              />
              <FGEuroMaskInput name="minerval" label={t(ETLCodes.Minerval)} />
              <FGCheckboxInput name="isVisibleBulletin" label={t(ETLCodes.Bulletin)} />
              <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" rows={4} />
            </FieldGroup>
            <Divider />
          </SmallFormGenerator>
        )}
        {displaySearchTable && (
          <div style={{ marginTop: "0.5rem" }}>
            <InscriptionMasseTable
              idcentre={idCentre}
              idclasse={idClasse}
              anneeScolaire={anneeScolaire}
              codeMetier={codeMetier}
              selected={apprenants ?? []}
              setSelected={val => setApprenants(val)}
              saving={saving}
            />
          </div>
        )}
        <StyledError show={!idCentre} message={t(ETLCodes.PleaseChooseCentre)}></StyledError>
        <StyledError show={!idClasse} message={t(ETLCodes.PleaseChooseClasse)}></StyledError>
      </FieldSet>
      <ProgressBarDialog
        isOpen={inscriptionsEngaged}
        title={progressTitle}
        currentValue={currentInscriptionIndex}
        total={lastInscriptionIndex}
        autoClose={false}
        closeFromInside={() => setInscriptionsEngaged(false)}
      />
    </PageBase>
  );
};
