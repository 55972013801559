import { FGCustomInput, FGCustomPanel, FGTextInput, FieldGroup, Loading } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  EvaluationApi,
  FcbEvaluationApprenantListGetDto,
  FcbEvaluationApprenantListGetDtoFromJSON
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { ApprenantTooltip, FGReadOnlyInput, FGWalterCheckboxInput, SmallFormGenerator } from "../../../../components";
import { useDialog } from "../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const ApprenantContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export interface IEvaluationApprenantListProps {
  coteMax?: number;
  showNeutraliseCheckbox: boolean;
  editable?: boolean;
}

export const EvaluationApprenantList: React.FunctionComponent<IEvaluationApprenantListProps> = ({
  coteMax,
  showNeutraliseCheckbox,
  editable = true
}) => {
  const { id, state } = useParams<{ id: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(EvaluationApi);
  const history = useHistory();
  const { showDialogPromise } = useDialog();

  const { data, loading, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +id <= 0
            ? FcbEvaluationApprenantListGetDtoFromJSON({ evaluationApprenantDtos: [] })
            : api.evaluationGetApprenantList({ id: +id }),
        saveApiFn: async (d: FcbEvaluationApprenantListGetDto) => {
          if (d.isFromWalter) {
            const result = await showDialogPromise({
              title: t(ETLCodes.ConfirmSaveEvaluationListTitle),
              message: t(ETLCodes.ConfirmSaveEvaluationListMessage)
            });
            if (result === "yes") {
              return api.evaluationSaveEvaluationDetails({ FcbEvaluationApprenantListGetDto: d });
            }
          } else {
            return api.evaluationSaveEvaluationDetails({ FcbEvaluationApprenantListGetDto: d });
          }
        },
        onSavedRoute: d => `${ERoutes.evaluation}/${+id}/detail/${state}`,
        deleteApiFn: d => {},
        getDeps: [id]
      }),
      [api, id, showDialogPromise, state, t]
    )
  );

  const coteReadonly = React.useMemo(
    () => ["A", "D", "J", "-", "T", "O", "DN", "DP", "DM", "N", "S", "U", "V", "C", "E", "Z", ""],
    []
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      evaluationApprenantDtos: Yup.array().of(
        Yup.object().shape({
          coteValue: Yup.string()
            .test("cote-positive-number", t(ETLCodes.CoteNombrePositifOuLettreAcceptee), (value: any) => {
              if (!value) {
                return true;
              } else if (value.includes(",")) {
                return +value.replace(",", ".") > -1;
              } else if (isNaN(+value)) {
                return coteReadonly.includes(value);
              } else {
                return +value > -1;
              }
            })
            .test("cote-max", t(ETLCodes.GreaterThanCoteMax), (value: any) => {
              if (!value) {
                return true;
              } else if (value.includes(",")) {
                return +value.replace(",", ".") <= coteMax;
              } else if (isNaN(+value)) {
                return coteReadonly.includes(value);
              } else if (coteMax) {
                return +value <= coteMax;
              }
            }),
          coteRemediation: Yup.string()
            .nullable()
            .test("cote-remediation-positive-number", t(ETLCodes.CoteNombrePositifOuLettreAcceptee), (value: any) => {
              if (!value) {
                return true;
              } else if (value.includes(",")) {
                return +value.replace(",", ".") > -1;
              } else if (isNaN(+value)) {
                return false;
              } else {
                return +value > -1;
              }
            })
            .test("cote-remediation-max", t(ETLCodes.GreaterThanCoteMax), (value: any) => {
              if (!value) {
                return true;
              } else if (value.includes(",")) {
                return +value.replace(",", ".") <= coteMax;
              } else if (isNaN(+value)) {
                return false;
              } else if (coteMax) {
                return +value <= coteMax;
              }
            })
        })
      )
    });
  }, [coteMax, coteReadonly, t]);

  return (
    <Loading isLoading={loading}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        validationSchema={FormSchema}
        showColons
        editMode={state === "edit"}
        minLabelWidth={180}
        labelAlignment="left"
        formatDate="dd/MM/yyyy"
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(`${ERoutes.evaluation}`)}
        saving={saving}
        disabled={!editable}
      >
        <FieldGroup columns={[2, 1, 1, 2, 2, 1, 2]}>
          <FGCustomInput label={t(ETLCodes.Apprenant)}>{() => <></>}</FGCustomInput>
          <FGCustomInput label={t(ETLCodes.Presence)}>{() => <></>}</FGCustomInput>
          {showNeutraliseCheckbox && (
            <FGCustomPanel>
              {ctx => <FGCustomInput label={t(ETLCodes.Neutralise)}>{() => <></>}</FGCustomInput>}
            </FGCustomPanel>
          )}
          <FGCustomInput label={t(ETLCodes.Cote)}>{() => <></>}</FGCustomInput>
          <FGCustomInput label={t(ETLCodes.Remarque)}>{() => <></>}</FGCustomInput>
          <FGCustomInput label={t(ETLCodes.Remediation)}>{() => <></>}</FGCustomInput>
          <FGCustomInput label={t(ETLCodes.CoteRemediation)}>{() => <></>}</FGCustomInput>
        </FieldGroup>
        {data?.evaluationApprenantDtos.map((element, index) => {
          return (
            <div key={index}>
              <FieldGroup columns={[2, 1, 1, 2, 2, 1, 2]}>
                <FGReadOnlyInput
                  readonly
                  name={`evaluationApprenantDtos[${index}].apprenant`}
                  leftElement={ctx => (
                    <ApprenantContainer>
                      <ApprenantTooltip idApprenant={element.idApprenant} showId={false} />
                      {element.apprenant}
                    </ApprenantContainer>
                  )}
                ></FGReadOnlyInput>
                <FGTextInput name={`evaluationApprenantDtos[${index}].statut`} readonly />
                {showNeutraliseCheckbox && (
                  <FGCustomPanel>
                    {ctx => (
                      <FGWalterCheckboxInput
                        name={`evaluationApprenantDtos[${index}].neutraliseValue`}
                        readonly={ctx.formik.values.evaluationApprenantDtos[index].remediationConseil === true}
                        disabled={element.disabled}
                      />
                    )}
                  </FGCustomPanel>
                )}
                <FGCustomPanel>
                  {ctx => (
                    <FGTextInput
                      name={`evaluationApprenantDtos[${index}].coteValue`}
                      readonly={
                        element.presence === "A" ||
                        ctx.formik.values.evaluationApprenantDtos[index].neutraliseValue === true
                      }
                      tabIndex={index + 1}
                      disabled={element.disabled}
                    />
                  )}
                </FGCustomPanel>
                <FGTextInput
                  name={`evaluationApprenantDtos[${index}].remarque`}
                  tabIndex={data.evaluationApprenantDtos.length + index + 1}
                  maxLength={500}
                  disabled={element.disabled}
                />
                <FGCustomPanel>
                  {ctx => (
                    <FGWalterCheckboxInput
                      name={`evaluationApprenantDtos[${index}].remediationConseil`}
                      readonly={ctx.formik.values.evaluationApprenantDtos[index].neutraliseValue === true}
                      disabled={element.disabled}
                    />
                  )}
                </FGCustomPanel>
                <FGCustomPanel>
                  {ctx => (
                    <FGTextInput
                      name={`evaluationApprenantDtos[${index}].coteRemediation`}
                      readonly={ctx.formik.values.evaluationApprenantDtos[index].remediationConseil === false}
                      disabled={element.disabled}
                    />
                  )}
                </FGCustomPanel>
              </FieldGroup>
            </div>
          );
        })}
      </SmallFormGenerator>
    </Loading>
  );
};
