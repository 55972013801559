import { ISelectorProps, Selector } from "./Selector";
import * as React from "react";

import { useTl } from "../../hooks/useTl";
import { ETLCodes } from "../../locales/ETLCodes";
import { IconNames } from "@blueprintjs/icons";

export interface IWSelectorProps
  extends Omit<
    ISelectorProps<any>,
    | "addAllText"
    | "addAllTtitleFunc"
    | "addAllTextFunc"
    | "addElementTextFunc"
    | "removeAllText"
    | "removeAllTitleFunc"
    | "removeAllTextFunc"
    | "removeElementTextFunc"
    | "resultText"
    | "cancelText"
    | "saveText"
  > {
  defaultPageSize?: number;
  defaultAvailablePageSizes?: number[];
}

export const WSelector: React.FunctionComponent<IWSelectorProps> = props => {
  const { t } = useTl();
  return (
    <Selector
      addAllTitleFunc={() => t(ETLCodes.AddAll)}
      addAllTextFunc={count => t(ETLCodes.AddAllText, { count })}
      addElementTextFunc={count => t(ETLCodes.AddElementText, { count })}
      removeAllTitleFunc={() => t(ETLCodes.RemoveAll)}
      removeAllTextFunc={count => t(ETLCodes.RemoveAllText, { count })}
      removeElementTextFunc={count => t(ETLCodes.RemoveElementText, { count })}
      resultText={t(ETLCodes.Resultat)}
      cancelText={t(ETLCodes.Retour)}
      saveText={t(ETLCodes.General_Save)}
      saveIcon={IconNames.FLOPPY_DISK}
      cancelIcon={IconNames.RESET}
      {...props}
    ></Selector>
  );
};
