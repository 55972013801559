import { FGTextInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { FGEditableSelectInput, FGWalterSelectInput } from ".";
import { EmailDto, EmailDtoFromJSON, PersonneApi } from "../../api";
import { useApiService } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { nameof } from "../../utils";

export interface IFGEditableEmailSelectInputProps
  extends Omit<IFGSelectInputProps<EmailDto>, "valueField" | "displayField" | "items" | "loading"> {
  autoSelectIfOne?: boolean;
  idpersonne: number;
}

export const FGEditableEmailSelectInput: React.FunctionComponent<IFGEditableEmailSelectInputProps> = ({
  idpersonne,
  ...props
}) => {
  const api = useApiService(PersonneApi);

  const fetchEmails = React.useCallback(async () => await api.personneGetEmails({ idpersonne }), [api, idpersonne]);
  const { data: items, isFetching, refetch } = useQuery(["emailsdto-personne", idpersonne], fetchEmails);

  const [typeTels, ttLoading] = useReferential(a => a.referentialGetTypesEmail());

  const onSave = React.useCallback(
    async (data: EmailDto) => {
      return await api.personneSaveEmail({ EmailDto: data });
    },
    [api]
  );

  const onDelete = React.useCallback(
    async (data: EmailDto) => {
      await api.personneDeleteEmailFromPersonne({ idemail: data.idemail ?? 0 });
    },
    [api]
  );

  return (
    <FGEditableSelectInput
      displayField={nameof<EmailDto>("adresseEmail")}
      valueField={nameof<EmailDto>("idemail")}
      baseData={EmailDtoFromJSON({ idpersonne })}
      refresh={async () => {
        await refetch();
      }}
      editFields={
        <>
          <FGWalterSelectInput
            name={nameof<EmailDto>("idtypeEmail")}
            noLabel
            items={typeTels}
            loading={ttLoading}
            requiredMark
          />
          <FGTextInput name={nameof<EmailDto>("adresseEmail")} noLabel maxLength={100} requiredMark />
        </>
      }
      onSave={onSave}
      onDelete={onDelete}
      items={items}
      loading={isFetching}
      {...props}
    />
  );
};

export default FGEditableEmailSelectInput;
