import { Button, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";

import { useAuth } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IAddButtonProps extends ICustomButtonProps {
  icon?: IconName | MaybeElement;
  fill?: boolean;
  tooltipText?: string;
}

export const AddButton: React.FunctionComponent<IAddButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  icon = IconNames.ADD,
  tooltipText,
  fill = false,
  small
}) => {
  const { t } = useTl();
  const { getCurrentPermission } = useAuth();
  const hasRights = React.useMemo(() => getCurrentPermission()?.permission?.includes("RW"), [getCurrentPermission]);

  const finalText = React.useMemo(() => text || (minimal ? null : t(ETLCodes.General_Add)), [minimal, t, text]);

  return hasRights ? (
    !!tooltipText ? (
      <ToolTipButton
        tooltip={tooltipText}
        icon={icon}
        minimal={minimal}
        onClick={onClick}
        text={finalText}
        intent={intent}
        loading={loading}
        disabled={disabled}
        fill={fill}
        small={small}
      ></ToolTipButton>
    ) : (
      <Button
        icon={icon}
        minimal={minimal}
        onClick={onClick}
        text={finalText}
        intent={intent}
        loading={loading}
        disabled={disabled}
        fill={fill}
        small={small}
      />
    )
  ) : (
    <></>
  );
};
