/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ClasseHoraireMatiereSearch,
  FcbClasseHoraireMatiereGridDtoPaginatedResults,
  FcbClasseHorairesDto,
  FcbHoraireGridDtoPaginatedResults,
  FilterCriteriaInfo,
  HoraireSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ClasseHoraireMatiereSearchFromJSON,
    ClasseHoraireMatiereSearchToJSON,
    FcbClasseHoraireMatiereGridDtoPaginatedResultsFromJSON,
    FcbClasseHoraireMatiereGridDtoPaginatedResultsToJSON,
    FcbClasseHorairesDtoFromJSON,
    FcbClasseHorairesDtoToJSON,
    FcbHoraireGridDtoPaginatedResultsFromJSON,
    FcbHoraireGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    HoraireSearchFromJSON,
    HoraireSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ClasseHoraireAjoutDateHoraireRequest {
    idClasse?: number;
    dayToAdd?: Date;
}

export interface ClasseHoraireBaseSearchRequest {
    HoraireSearch?: HoraireSearch;
}

export interface ClasseHoraireCanSaveRequest {
    idClasse?: number;
}

export interface ClasseHoraireCreateMissingHoraireRequest {
    idClasse?: number;
}

export interface ClasseHoraireDeleteHoraireRequest {
    idClasse?: number;
}

export interface ClasseHoraireFermetureClasseRequest {
    idClasse?: number;
    dateFermeture?: Date;
}

export interface ClasseHoraireGetHoraireMatieresRequest {
    ClasseHoraireMatiereSearch?: ClasseHoraireMatiereSearch;
}

export interface ClasseHoraireGetHorairesRequest {
    idClasse?: number;
}

export interface ClasseHoraireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseHoraireGetSelectItemsRequest {
    searchField: string;
}

export interface ClasseHoraireIsHoraireLinkedToGapRequest {
    idClasse?: number;
}

export interface ClasseHoraireSaveRequest {
    FcbClasseHorairesDto?: FcbClasseHorairesDto;
}

export interface ClasseHoraireSaveADistanceRequest {
    idclasseHoraire?: number;
    value?: boolean;
}

export interface ClasseHoraireSaveCheckRequest {
    FcbClasseHorairesDto?: FcbClasseHorairesDto;
}

/**
 * 
 */
export class ClasseHoraireApi extends runtime.BaseAPI {

    /**
     */
    async classeHoraireAjoutDateHoraireRaw(requestParameters: ClasseHoraireAjoutDateHoraireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.dayToAdd !== undefined) {
            queryParameters['dayToAdd'] = (requestParameters.dayToAdd as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/AjoutDateHoraire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeHoraireAjoutDateHoraire(requestParameters: ClasseHoraireAjoutDateHoraireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeHoraireAjoutDateHoraireRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeHoraireBaseSearchRaw(requestParameters: ClasseHoraireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbHoraireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HoraireSearchToJSON(requestParameters.HoraireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbHoraireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireBaseSearch(requestParameters: ClasseHoraireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbHoraireGridDtoPaginatedResults> {
        const response = await this.classeHoraireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireCanSaveRaw(requestParameters: ClasseHoraireCanSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/CanSave`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireCanSave(requestParameters: ClasseHoraireCanSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.classeHoraireCanSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireCreateMissingHoraireRaw(requestParameters: ClasseHoraireCreateMissingHoraireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/CreateMissingHoraire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeHoraireCreateMissingHoraire(requestParameters: ClasseHoraireCreateMissingHoraireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeHoraireCreateMissingHoraireRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeHoraireDeleteHoraireRaw(requestParameters: ClasseHoraireDeleteHoraireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/DeleteHoraire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeHoraireDeleteHoraire(requestParameters: ClasseHoraireDeleteHoraireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeHoraireDeleteHoraireRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeHoraireFermetureClasseRaw(requestParameters: ClasseHoraireFermetureClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.dateFermeture !== undefined) {
            queryParameters['dateFermeture'] = (requestParameters.dateFermeture as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/FermetureClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeHoraireFermetureClasse(requestParameters: ClasseHoraireFermetureClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeHoraireFermetureClasseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeHoraireGetHoraireMatieresRaw(requestParameters: ClasseHoraireGetHoraireMatieresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseHoraireMatiereGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/GetHoraireMatieres`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseHoraireMatiereSearchToJSON(requestParameters.ClasseHoraireMatiereSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseHoraireMatiereGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireGetHoraireMatieres(requestParameters: ClasseHoraireGetHoraireMatieresRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseHoraireMatiereGridDtoPaginatedResults> {
        const response = await this.classeHoraireGetHoraireMatieresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireGetHorairesRaw(requestParameters: ClasseHoraireGetHorairesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseHorairesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/GetHoraires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseHorairesDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireGetHoraires(requestParameters: ClasseHoraireGetHorairesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseHorairesDto> {
        const response = await this.classeHoraireGetHorairesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireGetSearchCriteriasRaw(requestParameters: ClasseHoraireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeHoraireGetSearchCriterias(requestParameters: ClasseHoraireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeHoraireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireGetSelectItemsRaw(requestParameters: ClasseHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeHoraireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeHoraireGetSelectItems(requestParameters: ClasseHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeHoraireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireIsHoraireLinkedToGapRaw(requestParameters: ClasseHoraireIsHoraireLinkedToGapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/IsHoraireLinkedToGap`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireIsHoraireLinkedToGap(requestParameters: ClasseHoraireIsHoraireLinkedToGapRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.classeHoraireIsHoraireLinkedToGapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireSaveRaw(requestParameters: ClasseHoraireSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseHorairesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/Save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseHorairesDtoToJSON(requestParameters.FcbClasseHorairesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseHorairesDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireSave(requestParameters: ClasseHoraireSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseHorairesDto> {
        const response = await this.classeHoraireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireSaveADistanceRaw(requestParameters: ClasseHoraireSaveADistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idclasseHoraire !== undefined) {
            queryParameters['idclasseHoraire'] = requestParameters.idclasseHoraire;
        }

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/SaveADistance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeHoraireSaveADistance(requestParameters: ClasseHoraireSaveADistanceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeHoraireSaveADistanceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeHoraireSaveCheckRaw(requestParameters: ClasseHoraireSaveCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraire/SaveCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseHorairesDtoToJSON(requestParameters.FcbClasseHorairesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireSaveCheck(requestParameters: ClasseHoraireSaveCheckRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeHoraireSaveCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
