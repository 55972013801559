import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ServiceTutelleApi, UsersServicesLiesApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IUtilisateurServicesLiesSelectorProps {}

export const UtilisateurServicesLiesSelector: React.FunctionComponent<IUtilisateurServicesLiesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const sApi = useApiService(ServiceTutelleApi);
  const mApi = useApiService(UsersServicesLiesApi);
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() => mApi.usersServicesLiesGetUserServices({ idUser: +id }));

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idsServiceTutelles}
        columns={columns}
        idField="idserviceTutelle"
        tlDataPrefix="ServiceSearchCriterias"
        title={t(ETLCodes.ServicesLies)}
        searchIdsFunc={sObj =>
          sApi.serviceTutelleSearchServiceTutelleIds({ ServiceTutelleSearch: { ...sObj, actifManual: true } })
        }
        getCriteriasFunction={() => sApi.serviceTutelleGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => sApi.serviceTutelleBaseSearch({ ServiceTutelleSearch: { ...sObj, actifManual: true } })}
        onSave={async newIds => {
          await mApi.usersServicesLiesSaveServices({
            UsersServicesLiesDto: { idUser: +id, idsServiceTutelles: newIds }
          });

          history.push(`${ERoutes.users}/${id}/services/${state}`);
        }}
        onCancel={() => history.push(`${ERoutes.users}/${id}/services/${state}`)}
        dialogContext={dialogContext}
        sortKeys={{ nom: "ASC" }}
        defaultPageSize={25}
      />
    )
  );
};
