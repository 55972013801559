import { Classes, ControlGroup } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomPanel, FGSelectInput, IFGSelectInputProps, showError, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AddButton, CancelButton, DeleteButton, EditButton, SaveButton, SmallFormGenerator } from "..";
import { useAuth } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

const ButtonsContainer = styled.div`
  & .${Classes.BUTTON} {
    min-height: 0 !important;
    height: 20px;
    min-width: 0 !important;
    border-radius: 11px;
  }
`;

export interface IFGEditableSelectInputProps<T> extends Omit<IFGSelectInputProps<T>, "valueField" | "displayField"> {
  onSave?: (data: T) => Promise<T>;
  onDelete?: (data: T) => Promise<void>;
  editFields?: React.ReactElement;
  valueField: string;
  displayField: string;
  baseData: T;
  refresh: () => Promise<void>;
  autoSelectIfOne?: boolean;
}

export function FGEditableSelectInput<T>({
  onSave,
  onDelete,
  editFields,
  autoSelectIfOne,
  baseData,
  refresh,
  ...props
}: IFGEditableSelectInputProps<T>) {
  const { t } = useTl();
  const { formik, editMode: formikEditMode } = useFGContext();
  const { hasPermission } = useAuth();
  const [data, setData] = React.useState<T>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const currentValue = React.useMemo(() => getIn(formik.values, props.name), [formik.values, props.name]);
  React.useEffect(() => {}, []);
  const currentItem = React.useMemo(() => props.items?.find(i => i[props.valueField] === currentValue), [
    currentValue,
    props.items,
    props.valueField
  ]);

  const buttons = React.useMemo(
    () =>
      formikEditMode &&
      !props.readonly &&
      !props.disabled && (
        <ButtonsContainer>
          {!!onSave && !!editFields && hasPermission("PERSONNE", "RW", "SIGNALETIQUE") && (
            <AddButton hideText onClick={() => setData(baseData)} small />
          )}
          {!!onSave && !!editFields && !!currentItem && hasPermission("PERSONNE", "RW", "SIGNALETIQUE") && (
            <EditButton hideText onClick={() => setData(currentItem)} />
          )}
          {!!onDelete && !!currentItem && hasPermission("PERSONNE", "RWD", "SIGNALETIQUE") && (
            <DeleteButton
              hideText
              showWarning
              onDelete={async () => {
                setIsDeleting(true);
                try {
                  await onDelete(currentItem);
                  await refresh();
                  formik?.setFieldValue(props.name, null);
                } catch (e) {
                  showError(t(ETLCodes.ErrorWhileDeleting));
                } finally {
                  setIsDeleting(false);
                }
              }}
              loading={isDeleting}
            />
          )}
        </ButtonsContainer>
      ),
    [
      baseData,
      currentItem,
      editFields,
      formik,
      formikEditMode,
      hasPermission,
      isDeleting,
      onDelete,
      onSave,
      props.disabled,
      props.name,
      props.readonly,
      refresh,
      t
    ]
  );

  const [hasBeenSet, setHasBeenSet] = React.useState(false);
  React.useEffect(() => {
    if (autoSelectIfOne && props.items?.length === 1 && !getIn(formik.initialValues, props.name) && !hasBeenSet) {
      formik.setFieldValue(props.name, props.items[0][props.valueField]);
      setHasBeenSet(true);
    }
  }, [formik, hasBeenSet, autoSelectIfOne, props.items, props.name, props.valueField]);

  return (
    <FGCustomPanel>
      {ctx =>
        !!data ? (
          <SmallFormGenerator initialValues={data} showDeleteButton={false} showRightButtons={false}>
            <ControlGroup>
              {editFields}
              <FGCustomPanel>
                {subCtx => (
                  <SaveButton
                    loading={isSaving}
                    onClick={async d => {
                      setIsSaving(true);
                      try {
                        const res = await onSave(subCtx.formik.values);
                        await refresh();
                        setData(null);
                        ctx.formik?.setFieldValue(props.name, res[props.valueField]);
                      } catch (e) {
                        showError(t(ETLCodes.ErrorWhileSaving));
                      } finally {
                        setIsSaving(false);
                      }
                    }}
                  />
                )}
              </FGCustomPanel>
              <CancelButton loading={isSaving} onClick={() => setData(null)} />
            </ControlGroup>
          </SmallFormGenerator>
        ) : (
          <FGSelectInput className="custom-select" rightElement={buttons} {...props} />
        )
      }
    </FGCustomPanel>
  );
}

export default FGEditableSelectInput;
