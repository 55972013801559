import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { CentreApi, UsersCentresLiesApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IUtilisateurCentresLiesSelectorProps {}

export const UtilisateurCentresLiesSelector: React.FunctionComponent<IUtilisateurCentresLiesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const cApi = useApiService(CentreApi);
  const mApi = useApiService(UsersCentresLiesApi);
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() => mApi.usersCentresLiesGetCentres({ idUser: +id }));

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idsCentres}
        columns={columns}
        idField="idcentre"
        tlDataPrefix="CentreSearchCriterias"
        title={t(ETLCodes.CentresLies)}
        searchIdsFunc={sObj => cApi.centreSearchCentreIds({ CentreSearch: sObj })}
        getCriteriasFunction={() => cApi.centreGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => cApi.centreBaseSearch({ CentreSearch: sObj })}
        onSave={async newIds => {
          await mApi.usersCentresLiesSaveCentres({
            FcbUsersCentresLiesDto: { idUser: +id, idsCentres: newIds }
          });

          history.push(`${ERoutes.users}/${id}/centres/${state}`);
        }}
        onCancel={() => history.push(`${ERoutes.users}/${id}/centres/${state}`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ nom: "ASC" }}
        defaultPageSize={25}
      />
    )
  );
};
