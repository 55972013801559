import { FGCustomPanel, FGEmpty, FGMaskInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ConseillerPedagogiqueApi, FcbConseillerPedagogiqueDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AdresseDoublonErrorLink,
  CommuneSelect,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGPersonneCodePostal,
  FGWalterCheckboxInput,
  FGWalterSelectInput,
  LocaliteSelect,
  SmallFormGenerator
} from "../../../../../components";
import FGCopyTextInput from "../../../../../components/formGenerator/FGCopyTextInput";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { phoneRegex } from "../../../../../utils/phoneRegex";

interface IConseillerPedagogiqueDetailPageProps {
  idConseillerPedagogique: string;
  editMode: boolean;
}

export const ConseillerPedagogiqueDetailPage: React.FunctionComponent<IConseillerPedagogiqueDetailPageProps> = ({
  idConseillerPedagogique,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ConseillerPedagogiqueApi);
  const history = useHistory();

  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe(), false, []);
  const [pays, pLoading] = useReferential(a => a.referentialGetPays(), false, []);

  const { data, saving, saveItem, deleting, deleteItem, loading } = useCrudApi({
    getApiFn: () =>
      idConseillerPedagogique === "0"
        ? FcbConseillerPedagogiqueDtoFromJSON({})
        : api.conseillerPedagogiqueGetConseillerPedagogique({ id: idConseillerPedagogique }),
    saveApiFn: values => api.conseillerPedagogiqueSave({ FcbConseillerPedagogiqueDto: values }),
    deleteApiFn: values => api.conseillerPedagogiqueDelete({ id: values.idconseillerPedagogique }),
    onDeletedRoute: () => ERoutes.conseillerPedagogique,
    onSavedRoute: d => `${ERoutes.conseillerPedagogique}/${d.idconseillerPedagogique}/detail/edit`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idconseillerPedagogique: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === idConseillerPedagogique) return true;
          const validationError = await api.conseillerPedagogiqueCodeExists({ code: value });
          return validationError.isValid;
        }),
      codesexe: Yup.string().required(t(ETLCodes.Required)),
      nom: Yup.string().required(t(ETLCodes.Required)),
      prenom: Yup.string().required(t(ETLCodes.Required)),
      adresse: Yup.object().shape({
        adresse: Yup.string().required(t(ETLCodes.Required)),
        pays: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
      telephonePrive: Yup.object().test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (this.parent.idrepresentant > 0 || !value?.numero) return true;
        const numero = value?.numero as string;
        return !!numero.match(phoneRegex);
      }),
      telephone: Yup.object().test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (this.parent.idrepresentant > 0 || !value?.numero) return true;
        const numero = value?.numero as string;
        return !!numero.match(phoneRegex);
      }),
      gsm: Yup.object().test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (this.parent.idrepresentant > 0 || !value?.numero) return true;
        const numero = value?.numero as string;
        return !!numero.match(phoneRegex);
      }),
      email: Yup.object().when("idconseillerPedagogique", {
        is: idconseillerPedagogique => idconseillerPedagogique <= 0,
        then: Yup.object().shape({
          adresseEmail: Yup.string().email(t(ETLCodes.InvalidEmail))
        })
      }),
      faxPrive: Yup.string().test("gsm-prive-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
      fax: Yup.string().test("fax-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    });
  }, [api, idConseillerPedagogique, t]);

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);

  const isFullCreation = React.useMemo(() => +idConseillerPedagogique <= 0 && !data?.idpersonne, [
    data?.idpersonne,
    idConseillerPedagogique
  ]);

  return (
    <SmallFormGenerator
      loading={loading}
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      editMode={editMode}
      validationSchema={FormSchema}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idConseillerPedagogique !== "0"}
      onCancel={() => history.push(ERoutes.conseillerPedagogique)}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="idconseillerPedagogique" label={t(ETLCodes.Code)} maxLength={3} forceCase="upper" />
        <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
        <FGWalterSelectInput name="codesexe" label={t(ETLCodes.Genre)} loading={sLoading} items={sexe} />
        <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={50} />
      </FieldGroup>
      <FieldGroup columns={2}>
        {data?.hasAddressDoublons && (
          <>
            <AdresseDoublonErrorLink idpersonne={idpersonne} />
            <FGEmpty />
          </>
        )}
        <FGWalterSelectInput
          name="adresse.pays"
          label={t(ETLCodes.Pays)}
          items={pays}
          loading={pLoading}
          requiredMark
          disabled={data?.hasAddressDoublons}
        />
        <FGEmpty />
        <FGTextAreaInput
          name="adresse.adresse"
          label={t(ETLCodes.Adresse)}
          requiredMark
          disabled={data?.hasAddressDoublons}
        />
        <FGEmpty />
        <FGPersonneCodePostal
          idName="adresse.idcodePostal"
          textName="adresse.codePostalText"
          localiteName="adresse.localite"
          disabled={data?.hasAddressDoublons}
        />
        <FGEmpty />
        <FGCustomPanel>
          {ctx => (
            <LocaliteSelect
              codePostal={ctx.formik.values?.adresse?.codePostalText}
              codePays={ctx.formik.values?.adresse?.pays}
              name="adresse.localite"
              codePostalTextName="adresse.codePostalText"
              disableIfNoCp={false}
              disabled={data?.hasAddressDoublons}
            />
          )}
        </FGCustomPanel>
        <FGEmpty />
        <FGCustomPanel>
          {ctx => (
            <CommuneSelect
              codePostal={ctx.formik.values?.adresse?.codePostalText}
              codePays={ctx.formik.values?.adresse?.pays}
              name="adresse.commune"
              enableAutoSelect={
                ctx.formik.initialValues?.adresse?.codePostalText !== ctx.formik.values?.adresse?.codePostalText
              }
              disabled={data?.hasAddressDoublons}
            />
          )}
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup columns={2}>
        {isFullCreation ? (
          <FGCopyTextInput copyOnlyDigits={true} name="telephonePrive.numero" label={t(ETLCodes.Tel)} maxLength={20} />
        ) : (
          <FGEditableTelSelectInput
            name="telephonePrive.idtelephone"
            label={t(ETLCodes.Tel)}
            visible={!isFullCreation}
            autoSelectIfOne
            idpersonne={idpersonne}
            types={["PERSO"]}
          />
        )}
        <FGEmpty />
        {isFullCreation ? (
          <FGCopyTextInput copyOnlyDigits={true} name="telephone.numero" label={t(ETLCodes.TelPro)} maxLength={20} />
        ) : (
          <FGEditableTelSelectInput
            name="telephone.idtelephone"
            label={t(ETLCodes.Tel)}
            visible={!isFullCreation}
            autoSelectIfOne
            idpersonne={idpersonne}
            types={["PRO"]}
          />
        )}
        <FGEmpty />
        {isFullCreation ? (
          <FGCopyTextInput copyOnlyDigits={true} name="gsm.numero" label={t(ETLCodes.Gsm)} maxLength={20} />
        ) : (
          <FGEditableTelSelectInput
            name="gsm.idtelephone"
            label={t(ETLCodes.Gsm)}
            visible={!isFullCreation}
            autoSelectIfOne
            idpersonne={idpersonne}
            types={["GSM_PERSO", "GSM_PRO"]}
          />
        )}
        <FGEmpty />
        <FGTextInput name="faxPrive" label={t(ETLCodes.FaxPrive)} maxLength={20} />
        <FGTextInput name="fax" label={t(ETLCodes.Fax)} maxLength={20} />
        <FGMaskInput
          name="noNational"
          label={t(ETLCodes.NumeroNational)}
          cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
        />
      </FieldGroup>
      <FieldGroup columns={2}>
        {isFullCreation ? (
          <FGTextInput name="email.adresseEmail" label={t(ETLCodes.General_Email)} maxLength={100} />
        ) : (
          <FGEditableEmailSelectInput
            name="email.idemail"
            label={t(ETLCodes.General_Email)}
            visible={!isFullCreation}
            autoSelectIfOne
            idpersonne={idpersonne}
          />
        )}
        <FGEmpty />
        <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        <FGEmpty />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
