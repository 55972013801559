import { isAfter, isSameDay } from "date-fns";
import { FormikProps } from "formik";
import isEqual from "lodash/isEqual";
import {
  FGCustomInput,
  FGCustomPanel,
  FGMaskInput,
  FGMultiSuggestInput,
  FGNumberInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import * as Yup from "yup";

import {
  DateDuringContratDtoFromJSON,
  FichierApi,
  ReferentialApi,
  TuteurApi,
  TuteurEditDto,
  TuteurEditDtoFromJSON,
  TuteurLieuDoublonSearchDto
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  CustomBulletList,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  FGWalterSuggestTextInput,
  NationaliteSelect,
  SmallFormGenerator
} from "../../../../../../components";
import FGCopyTextInput from "../../../../../../components/formGenerator/FGCopyTextInput";
import { useBeIdCardContext, useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { belgianIdValidate } from "../../../../../../utils/belgianIdValidate";
import { phoneRegex } from "../../../../../../utils/phoneRegex";
import pick from "lodash/pick";

export interface ILieuFormationTuteurProps {
  baseData: TuteurLieuDoublonSearchDto;
}

export const LieuFormationTuteur: React.FunctionComponent<ILieuFormationTuteurProps> = ({ baseData }) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const history = useHistory();
  const api = useApiService(TuteurApi);
  const refApi = useApiService(ReferentialApi);
  const formikRef = React.useRef<FormikProps<TuteurEditDto>>();
  const [metiers, mLoading] = useReferential(a => a.referentialGetMetier(), true);
  const { search } = useLocation();

  const { id, state, subId: idTuteurLieu } = useParams<{ id: string; state: string; subId: string }>();
  const { card } = useBeIdCardContext();
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const [hasContratDuringSortie, setHasContratDuringSortie] = React.useState(false);
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi<TuteurEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname.toUpperCase(),
              prenom: card?.firstName,
              dateNaissance: card?.dateOfBirthParsed,
              registreNational: card?.nationalNumber
            };
          }
          return +idTuteurLieu <= 0
            ? TuteurEditDtoFromJSON({
                ...newData,
                idtuteurLieu: 0,
                idlieuFormation: +id,
                actif: true
              })
            : api.tuteurGetTuteurLieu({ IdtuteurLieu: +idTuteurLieu });
        },
        saveApiFn: async (d: TuteurEditDto) => {
          if (
            (isAfter(new Date(), d.sortieEntreprise) || isSameDay(d.sortieEntreprise, new Date())) &&
            d.actif === true
          ) {
            await showDialogPromise({
              message: t(ETLCodes.TuteurDisablingForSortie),
              buttons: [
                {
                  intent: "success",
                  text: t(ETLCodes.OK),
                  resultType: "ok"
                }
              ]
            });
            d.actif = false;
          }
          return api.tuteurSaveTuteurLieu({ TuteurEditDto: d });
        },
        deleteApiFn: (d: TuteurEditDto) => api.tuteurDeleteTuteurLieu({ IdtuteurLieu: d.idtuteurLieu }),
        onDeletedRoute: () => `${ERoutes.lieuFormation}/${+id}${ERoutes.tuteur}/${state}${search}`,
        onSaved: d => sendMessage(d.idtuteur, true),
        onSavedRoute: d => `${ERoutes.lieuFormation}/${+id}${ERoutes.tuteur}/${state}${search}`
      }),
      [api, baseData, card, id, idTuteurLieu, search, sendMessage, showDialogPromise, state, t]
    )
  );

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);

  const [hopeUsers, huLoading] = useReferential(a => a.referentialGetHopeUsers(), false, []);
  const fetchExistingFunction = React.useCallback(() => {
    return refApi.referentialGetFonctionsTuteurs();
  }, [refApi]);
  const { data: existingFonctions, isFetching: efLoading } = useQuery("existing-functions", fetchExistingFunction, {
    cacheTime: 0
  });

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.TuteurLieuIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  React.useEffect(() => {
    if (data && card) {
      const cardObj = TuteurEditDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        +idTuteurLieu > 0 &&
        (!isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) || cardBirthdateString !== dataBirthdateString)
      ) {
        askDataDifferent();
      }
    }
  }, [askDataDifferent, card, data, idTuteurLieu]);

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      formikRef?.current?.setValues(
        TuteurEditDtoFromJSON({
          ...data,
          nom: card?.surname.toUpperCase() ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          nationalite: data.nationalite ?? "BE",
          registreNational: card?.nationalNumber ?? data.registreNational
        }),
        true
      );
    }
  }, [card?.dateOfBirthParsed, card?.firstName, card?.nationalNumber, card?.surname, cardDataDifferent, data]);

  const isFullCreation = React.useMemo(() => +idTuteurLieu <= 0 && !data?.idpersonne, [data?.idpersonne, idTuteurLieu]);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        nom: Yup.string().required(t(ETLCodes.Required)),
        prenom: Yup.string().required(t(ETLCodes.Required)),
        codesexe: Yup.string().required(t(ETLCodes.Required)),
        dateNaissance: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required)),
        nationalite: Yup.string().required(t(ETLCodes.Required)),
        registreNational: Yup.string()
          .nullable()
          .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
            if (!value || value === "") return true;

            var millenial = false;
            if (this.parent.dateNaissance) {
              const parsedDateNaissance = new Date(this.parent.dateNaissance);
              if (parsedDateNaissance.getFullYear() > 1999) {
                millenial = true;
              }
            }

            return belgianIdValidate(value, millenial);
          })
          .when(["nationalite", "numeroIdentification"], {
            is: (nationalite, numeroIdentification) => nationalite === "BE" || !numeroIdentification,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        numeroIdentification: Yup.string()
          .nullable()
          .when(["nationalite", "registreNational"], {
            is: (nationalite, registreNational) => nationalite !== "BE" && !registreNational,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        telephone: Yup.object().shape({
          idtelephone: Yup.number()
            .nullable()
            .test("telid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (
                (!context.from[1].value.idtuteurLieu || context.from[1].value.idtuteurLieu <= 0) &&
                !context.from[1].value.idpersonne
              )
                return true;
              return !!value || !!context.from[1].value.gsm?.idtelephone || !!context.from[1].value.email?.idemail;
            }),
          numero: Yup.string()
            .nullable()
            .test("tel-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (context.from[1].value.idtuteurLieu > 0 || !!context.from[1].value.idpersonne) return true;
              return !!value || !!context.from[1].value.gsm?.numero || !!context.from[1].value.email?.adresseEmail;
            })
            .test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
              if (context.from[1].value.idtuteurLieu > 0 || !!context.from[1].value.idpersonne || !value) return true;
              const numero = value as string;
              return !!numero.match(phoneRegex);
            })
        }),
        gsm: Yup.object().shape({
          idtelephone: Yup.number()
            .nullable()
            .test("gsmid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (
                (!context.from[1].value.idtuteurLieu || context.from[1].value.idtuteurLieu <= 0) &&
                !context.from[1].value.idpersonne
              )
                return true;
              return (
                !!value || !!context.from[1].value.telephone?.idtelephone || !!context.from[1].value.email?.idemail
              );
            }),
          numero: Yup.string()
            .nullable()
            .test("gsm-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (context.from[1].value.idtuteurLieu > 0 || !!context.from[1].value.idpersonne) return true;
              return (
                !!value || !!context.from[1].value.telephone?.numero || !!context.from[1].value.email?.adresseEmail
              );
            })
            .test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
              if (context.from[1].value.idtuteurLieu > 0 || !!context.from[1].value.idpersonne || !value) return true;
              const numero = value as string;
              return !!numero.match(phoneRegex);
            })
        }),
        email: Yup.object().shape({
          idemail: Yup.number()
            .nullable()
            .test("emailid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (
                (!context.from[1].value.idtuteurLieu || context.from[1].value.idtuteurLieu <= 0) &&
                !context.from[1].value.idpersonne
              )
                return true;
              return (
                !!value || !!context.from[1].value.telephone?.idtelephone || !!context.from[1].value.gsm?.idtelephone
              );
            }),
          adresseEmail: Yup.string()
            .nullable()
            .when({ is: isFullCreation, then: Yup.string().email(t(ETLCodes.InvalidEmail)) })
            .test("email-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
              if (context.from[1].value.idtuteurLieu > 0 || !!context.from[1].value.idpersonne) return true;
              return !!value || !!context.from[1].value.telephone?.numero || !!context.from[1].value.gsm?.numero;
            })
        })
      },
      [
        ["numeroIdentification", "nationalite"],
        ["numeroIdentification", "registreNational"],
        ["registreNational", "nationalite"],
        ["registreNational", "numeroIdentification"]
      ]
    );
  }, [isFullCreation, t]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const checkDateSortieContrat = React.useCallback(
    async (value: Date) => {
      if (!value) {
        setHasContratDuringSortie(false);
        return;
      }

      const isDateDuringContrat = await api.tuteurIsDateDuringContrat({
        DateDuringContratDto: DateDuringContratDtoFromJSON({ idtuteurLieu: +idTuteurLieu, dateSortie: value })
      });
      setHasContratDuringSortie(isDateDuringContrat.value);
    },
    [api, idTuteurLieu]
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.lieuFormation}/${id}/tuteur/${state}${search}`)}
      saving={saving}
      formikInnerRef={i => (formikRef.current = i)}
      validationErrors={validationErrors}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idTuteurLieu > 0}
      watchChanges={{
        sortieEntreprise: checkDateSortieContrat,
        fichierCv: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierCv", null);
          }
        },
        fichierDerogation: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierDerogation", null);
          }
        }
      }}
      forceEnableSave={Object.values(baseData).some(el => !!el)}
      minLabelWidth={185}
    >
      <FieldGroup columns={3}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterSelectInput
            name="codesexe"
            label={t(ETLCodes.Genre)}
            items={sexe}
            loading={sLoading}
            isFilterable={false}
            isCleareable={false}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          <NationaliteSelect
            name="nationalite"
            codeSexeFieldName="codesexe"
            helperText={card && `${t(ETLCodes.NationaliteSurCarte)}: ${card?.nationality}`}
          />
          <FGCustomPanel>
            {ctx => (
              <FGMaskInput
                name="registreNational"
                label={t(ETLCodes.NumeroNational)}
                cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
                helperText={ctx => (
                  <>
                    {isRegNatDateNaiss(ctx) ? null : (
                      <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                    )}
                    {isRegNatGenre(ctx) ? null : (
                      <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                    )}
                  </>
                )}
                requiredMark={ctx?.formik?.values?.nationalite === "BE"}
              />
            )}
          </FGCustomPanel>
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
          />
          {isFullCreation ? (
            <FGCopyTextInput copyOnlyDigits={true} name="gsm.numero" label={t(ETLCodes.Gsm)} maxLength={20} />
          ) : (
            <FGEditableTelSelectInput
              name="gsm.idtelephone"
              label={t(ETLCodes.Gsm)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["GSM_PERSO", "GSM_PRO"]}
            />
          )}
          {isFullCreation ? (
            <FGCopyTextInput copyOnlyDigits={true} name="telephone.numero" label={t(ETLCodes.Tel)} maxLength={20} />
          ) : (
            <FGEditableTelSelectInput
              name="telephone.idtelephone"
              label={t(ETLCodes.Gsm)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["PERSO", "PRO"]}
            />
          )}
          {isFullCreation ? (
            <FGTextInput name="email.adresseEmail" label={t(ETLCodes.General_Email)} maxLength={100} />
          ) : (
            <FGEditableEmailSelectInput
              name="email.idemail"
              label={t(ETLCodes.General_Email)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
            />
          )}
          <FGWalterSuggestTextInput
            name="fonction"
            label={t(ETLCodes.Fonction)}
            items={existingFonctions}
            loading={efLoading}
          />
          <FGWalterDateMaskInput
            name="sortieEntreprise"
            label={t(ETLCodes.SortieEntreprise)}
            helperText={
              hasContratDuringSortie && (
                <span style={{ color: theme.warningColor }}>{t(ETLCodes.ConttratEnCoursACetteDate)}</span>
              )
            }
          />
          <FGWalterCheckboxInput name="contactPrincipal" label={t(ETLCodes.PersonneContactPrincipale)} />
        </FieldGroup>
        <FieldGroup>
          <FGWalterDateMaskInput name="dateCv" label={t(ETLCodes.DateCv)} />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterFileInput
                  name="fichierCv"
                  label={t(ETLCodes.Cv)}
                  downloadFn={ctx.formik.values?.fichierCv && (() => downloadFn(ctx.formik.values?.idfichierCv))}
                />
                {ctx.editMode ? (
                  <FGMultiSuggestInput
                    label={t(ETLCodes.Metiers)}
                    name="idmetiers"
                    items={metiers}
                    loading={mLoading}
                    isFilterable={true}
                  />
                ) : (
                  <FGCustomInput name="idmetiers" label={t(ETLCodes.Metiers)}>
                    {() => (
                      <CustomBulletList
                        items={
                          metiers
                            ?.filter(m => ctx?.formik?.values?.idmetiers?.includes(m.value))
                            ?.map(m => ({ text: m.label })) ?? []
                        }
                      />
                    )}
                  </FGCustomInput>
                )}
              </>
            )}
          </FGCustomPanel>
          <FGWalterCheckboxInput name="anneesExperience" label={t(ETLCodes.AnneesExperience)} />
          <FGWalterCheckboxInput name="tutorat" label={t(ETLCodes.Tutorat)} />
          <FGWalterCheckboxInput name="vdc" label={t(ETLCodes.Vdc)} />
          <FGWalterCheckboxInput name="derogation" label={t(ETLCodes.Derogation)} />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterSelectInput
                  name="idresponsableDerogation"
                  label={t(ETLCodes.ResponsableDerogation)}
                  items={hopeUsers}
                  loading={huLoading}
                  disabled={!ctx?.formik?.values?.derogation}
                />
                <FGWalterDateMaskInput
                  name="dateDerogation"
                  label={t(ETLCodes.DateDerogation)}
                  disabled={!ctx?.formik?.values?.derogation}
                />
              </>
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <FGWalterFileInput
                name="fichierDerogation"
                label={t(ETLCodes.Document)}
                downloadFn={
                  ctx.formik.values?.fichierDerogation && (() => downloadFn(ctx.formik.values?.idfichierDerogation))
                }
              />
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <FGWalterCheckboxInput
                name="actif"
                label={t(ETLCodes.Actif)}
                readonly={
                  isAfter(ctx.formik?.values?.sortieEntreprise, new Date()) ||
                  isSameDay(ctx.formik?.values?.sortieEntreprise, new Date())
                }
              />
            )}
          </FGCustomPanel>
          <FGNumberInput
            min={0}
            name="capaciteFormative"
            label={t(ETLCodes.CapaciteFormative)}
            helperText={ctx =>
              !ctx?.formik?.values?.capaciteFormative &&
              ctx?.formik?.values?.capaciteFormative !== 0 && (
                <span style={{ color: theme.warningColor }}>{t(ETLCodes.CapaciteFormativeNonRemplie)}</span>
              )
            }
          />
        </FieldGroup>
        <FieldGroup></FieldGroup>
      </FieldGroup>
      <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
    </SmallFormGenerator>
  );
};
