import { getIn } from "formik";
import { useFGContext } from "nsitools-react";
import * as React from "react";

import { FGWalterSelectInput } from ".";
import { useTl } from "../hooks";
import { useReferential } from "../hooks/useReferential";
import { ETLCodes } from "../locales";

interface INationaliteSelectProps {
  name: string;
  readonly?: boolean;
  codeSexeFieldName?: string;
  helperText?: string;
}

export const NationaliteSelect: React.FunctionComponent<INationaliteSelectProps> = ({
  name,
  readonly,
  codeSexeFieldName,
  helperText
}) => {
  const { t } = useTl();
  const ctx = useFGContext();
  const { formik } = ctx;
  const currentCodeSexe = React.useMemo(() => {
    if (!codeSexeFieldName) return null;
    return getIn(formik.values, codeSexeFieldName);
  }, [codeSexeFieldName, formik.values]);

  const [nationalite, nLoading] = useReferential(
    a => {
      return a.referentialGetNationalite({ codeSexe: currentCodeSexe });
    },
    false,
    [currentCodeSexe]
  );

  return (
    <FGWalterSelectInput
      name={name}
      label={t(ETLCodes.Nationalite)}
      loading={nLoading}
      items={nationalite}
      readonly={readonly}
      helperText={helperText}
    />
  );
};
