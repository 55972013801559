import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Cleave from "cleave.js/react";
import { FormikProps } from "formik";
import * as IBAN from "iban";
import { FGListen, FGNumberInput, FGTextInput, FieldGroup, useCleaveOptions } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { FcbFormateurDetailDto, FcbFormateurDetailDtoFromJSON, FormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGEditableCompteBancaireSelectInput,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator,
  StyledError
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const IbanDisplay = styled(Cleave)`
  box-shadow: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus,
  :focus-visible {
    outline: none;
  }
`;

export interface IFormateurAdministratifItemPageProps {
  formateurId: number;
}

export const FormateurAdministratifItemPage: React.FunctionComponent<IFormateurAdministratifItemPageProps> = ({
  formateurId
}) => {
  const { t } = useTl();
  const api = useApiService(FormateurApi);
  const history = useHistory();
  const { state } = useParams<{ idFormateur: string; state: string }>();

  const [civilite, loadingCivilite] = useReferential(a => a.referentialGetCivilites(), true, []);
  const formikRef = React.useRef<FormikProps<FcbFormateurDetailDto>>();
  const [idcompteBancaire, setIdcompteBancaire] = React.useState<number>(null);
  const [showBicError, setShowBicError] = React.useState(false);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      compteBancaire: Yup.object()
        .nullable()
        .shape({
          iban: Yup.string()
            .nullable()
            .test("iban-valid", t(ETLCodes.InvalidIban), async function(value) {
              if (!value || value.trim() === "" || !!this.parent.idcompteBancaire) return true;
              return IBAN.isValid(value);
            }),
          idcompteBancaire: Yup.number()
            .nullable()
            .test("iban-valid-id", t(ETLCodes.InvalidIban), async function(value) {
              if (!value || !this.parent.iban || this.parent.iban.trim() === "") return true;
              return IBAN.isValid(this.parent.iban);
            })
        }),
      precompte: Yup.number().max(99999999999999999, t(ETLCodes.MaxNumberPrecompte))
    });
  }, [t]);

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      formateurId <= 0 ? FcbFormateurDetailDtoFromJSON({ idFormateur: 0 }) : api.formateurGet({ id: formateurId }),
    saveApiFn: d => {
      setShowBicError(false);
      return api.formateurSave({ FcbFormateurDetailDto: d });
    },
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/administratif`,
    deleteApiFn: () => {}
  });

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);
  const [, , , rawPersonneComptesBancaires] = useReferential(
    a => a.referentialGetComptesBancairesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const selectedCompteBancaire = React.useMemo(
    () => rawPersonneComptesBancaires?.find(c => +c.idValue === idcompteBancaire),
    [idcompteBancaire, rawPersonneComptesBancaires]
  );
  const bic = React.useMemo(() => selectedCompteBancaire?.keyValue, [selectedCompteBancaire?.keyValue]);
  const bankAccount = React.useMemo(() => selectedCompteBancaire?.displayValue, [selectedCompteBancaire?.displayValue]);

  const errorBicAccount = React.useMemo(() => {
    const accountChanged = bankAccount !== formikRef?.current?.initialValues?.compteBancaire?.iban;
    const bicChanged = bic !== formikRef?.current?.initialValues?.compteBancaire?.bic;

    return !accountChanged && bicChanged && !!bic;
  }, [bankAccount, bic]);

  const ibanCleaveOptions = useCleaveOptions("iban");

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={state === "edit"}
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.formateur)}
        saving={saving}
        formikInnerRef={i => (formikRef.current = i)}
      >
        <FGListen
          field="compteBancaire.idcompteBancaire"
          debounceTimeInMs={500}
          onChanged={(value, formik) => {
            if (!!value) {
              setIdcompteBancaire(value);
            }
            if (value === null) {
              formik.setFieldValue("compteBancaire.iban", null);
              formik.setFieldValue("compteBancaire.bic", null);
            }
          }}
        />
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            name="idcivilite"
            label={t(ETLCodes.EtatCivil)}
            items={civilite}
            loading={loadingCivilite}
          />
          <FGEditableCompteBancaireSelectInput
            name="compteBancaire.idcompteBancaire"
            label={t(ETLCodes.NumeroBanque)}
            idpersonne={idpersonne}
            renderItem={item => <IbanDisplay options={ibanCleaveOptions} readOnly value={item?.iban} />}
            helperText={ctx => {
              var foundBic = rawPersonneComptesBancaires?.find(
                c => +c.idValue === ctx.formik?.values?.compteBancaire?.idcompteBancaire
              )?.keyValue;
              if (!foundBic) return;
              return `${t(ETLCodes.CodeBIC)}: ${foundBic}`;
            }}
          />
          <FGNumberInput
            rightElement={<Icon icon={IconNames.PERCENTAGE} />}
            max={100}
            name="precompte"
            label={t(ETLCodes.Precompte)}
          />
          <FGWalterDateMaskInput name="dateDeces" label={t(ETLCodes.DateDeces)} />
          <FGWalterCheckboxInput name="conjointCharge" label={t(ETLCodes.ConjointCharge)} />
          <FGNumberInput name="enfantCharge" label={t(ETLCodes.EnfantACharge)} max={9999999999} min={0} />
          <FGNumberInput name="autreCharge" label={t(ETLCodes.AutreACharge)} max={9999999999} />
          <FGWalterCheckboxInput name="handicape" label={t(ETLCodes.FormateurHandicape)} />
          <FGNumberInput name="enfantHandicape" label={t(ETLCodes.EnfantHandicape)} max={9999999999} min={0} />
          <FGNumberInput name="autreHandicape" label={t(ETLCodes.AutreHandicape)} max={9999999999} />
          <FGTextInput name="ancienMatricule" label={t(ETLCodes.AncienMatricule)} maxLength={10} readonly />
          <FGWalterDateMaskInput name="datePremierContrat" label={t(ETLCodes.DateEntreeIfapme)} readonly={true} />
          <FGWalterCheckboxInput name="jure" label={t(ETLCodes.Jure)} />
        </FieldGroup>
        <StyledError
          show={!loading && errorBicAccount && showBicError}
          message={t(ETLCodes.BicChangedAccountUnchanged)}
        ></StyledError>
      </SmallFormGenerator>
    )
  );
};
