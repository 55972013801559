import { Button, Intent } from "@blueprintjs/core";
import { IconNames, IconName } from "@blueprintjs/icons";
import * as React from "react";
import styled from "styled-components";

import {
  BaseFilterCriteriaInfo,
  FieldSet,
  IDataTableColumn,
  IDialogContext,
  ISearchCriteriaValues,
  SortType,
  ToolTipButton
} from "nsitools-react";
import { InlineSearchCriteriaPanel } from "nsitools-react";
import { GridSelector } from "./GridSelector";
import { IInlineSearchCriteriaPanelProps } from "./MasseSelector";

export interface ISelectedSearchIdsProps {
  selectedIds: any[];
}

export interface IAvailableSearchIdsProps {
  availableIds: any[];
}

export interface ISelectorProps<TResults>
  extends Pick<IInlineSearchCriteriaPanelProps, "defaultCriterias" | "overrideListValues" | "tlDataPrefix"> {
  linkedIds: any[];
  onSave?: (newLinkedIds: any[]) => void;
  onCancel?: () => void;
  title?: string;
  addAllTitleFunc?: () => string;
  addAllTextFunc?: (count: number) => string;
  addElementTextFunc?: (count: number) => string;
  removeAllTitleFunc?: () => string;
  removeAllTextFunc?: (count: number) => string;
  removeElementTextFunc?: (count: number) => string;
  resultText?: string;
  cancelText?: string;
  saveText?: string;
  searchFunction: (sObj: ISearchCriteriaValues) => TResults | Promise<TResults>;
  getCriteriasFunction: () => Promise<BaseFilterCriteriaInfo[]>;
  searchIdsFunc: (sObj: any) => any[] | Promise<any[]>;
  columns: Array<IDataTableColumn>;
  idField: string;
  dialogContext: IDialogContext;
  sortKeys?: {
    [key: string]: SortType;
  };
  cancelIcon?: IconName;
  saveIcon?: IconName;
  defaultPageSize?: number;
  defaultAvailablePageSizes?: number[];
}

const BodyContainer = styled.div`
  & > * + * {
    margin-top: 2rem;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  & > * + * {
    margin-left: 1rem;
  }
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1rem;
  }
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  justify-content: center;
`;

export const InlineButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ButtonsBloc = styled.div`
  display: flex;
  margin-top: 0.5em;
  & > * + * {
    margin-left: 0.5em;
  }
`;

export function Selector<TResults>(props: ISelectorProps<TResults>) {
  const {
    linkedIds = [],
    onSave,
    onCancel,
    getCriteriasFunction,
    searchFunction,
    tlDataPrefix,
    defaultCriterias,
    overrideListValues,
    title = "Search",
    addAllTitleFunc = () => "Add all",
    addAllTextFunc = () => "Are you sure to add all elements ?",
    addElementTextFunc = () => "Add selected",
    removeAllTitleFunc = () => "Remove all",
    removeAllTextFunc = () => "Are you sure to remove all elements ?",
    removeElementTextFunc = () => "Remove selected",
    resultText = "Result",
    cancelText = "Cancel",
    saveText = "Save",
    searchIdsFunc,
    columns,
    idField,
    dialogContext,
    sortKeys = {},
    cancelIcon,
    saveIcon,
    defaultPageSize,
    defaultAvailablePageSizes
  } = props;

  const [linkedItems, setLinkedItems] = React.useState<any[]>(linkedIds);
  const [searchObject, setSearchObject] = React.useState({} as ISearchCriteriaValues);
  const { showDialog } = dialogContext;

  const search = React.useCallback(
    (sobj: ISearchCriteriaValues) => {
      setSearchObject(sobj);
    },
    [setSearchObject]
  );

  const selectedSearchObject = React.useMemo<ISelectedSearchIdsProps>(() => {
    return { ...searchObject, selectedIds: [...linkedItems] };
  }, [linkedItems, searchObject]);

  const availableSearchObject = React.useMemo<IAvailableSearchIdsProps>(() => {
    return {
      ...searchObject,
      availableIds: [...linkedItems]
    };
  }, [linkedItems, searchObject]);

  const [leftSelected, setLeftSelected] = React.useState<any[]>([]);
  const [rightSelected, setRightSelected] = React.useState<any[]>([]);
  const [leftFilter, setLeftFilter] = React.useState<string>();
  const [rightFilter, setRightFilter] = React.useState<string>();

  const [addAllLoading, setAddAllLoading] = React.useState(false);

  const addAll = React.useCallback(async () => {
    try {
      setAddAllLoading(true);
      let ids: any[] = [];
      const sObj = { ...availableSearchObject, filter: leftFilter };
      ids = await searchIdsFunc(sObj);

      showDialog({
        title: addAllTitleFunc(),
        message: addAllTextFunc(ids.length),
        onConfirmed: async () => {
          setLeftSelected([]);
          setLinkedItems(s => Array.from(new Set([...s, ...ids])));
        }
      });
    } catch (error) {}
    setAddAllLoading(false);
  }, [addAllTextFunc, addAllTitleFunc, availableSearchObject, leftFilter, searchIdsFunc, showDialog]);

  const addSelected = React.useCallback(() => {
    setLinkedItems(s => Array.from(new Set([...s, ...leftSelected])));
    setRightSelected([...leftSelected]);
    setLeftSelected([]);
  }, [leftSelected]);

  const [removeAllLoading, setRemoveAllLoading] = React.useState(false);

  const removeAll = React.useCallback(async () => {
    try {
      setRemoveAllLoading(true);
      let ids: any[] = [];
      const sObj = { ...selectedSearchObject, filter: rightFilter };
      ids = await searchIdsFunc(sObj);

      showDialog({
        title: removeAllTitleFunc(),
        message: removeAllTextFunc(ids.length),
        onConfirmed: async () => {
          setRightSelected([]);
          setLinkedItems(s => s.filter(id => !ids.includes(id)));
        }
      });
    } catch (error) {}
    setRemoveAllLoading(false);
  }, [removeAllTextFunc, removeAllTitleFunc, rightFilter, searchIdsFunc, selectedSearchObject, showDialog]);

  const removeSelected = React.useCallback(() => {
    setLinkedItems(s => s.filter(id => !rightSelected.includes(id)));
    setLeftSelected([...rightSelected]);
    setRightSelected([]);
  }, [rightSelected]);

  const [leftCount, setLeftCount] = React.useState(0);
  const [rightCount, setRightCount] = React.useState(0);

  return (
    <>
      <BodyContainer>
        <FieldSet title={title} collapsable={true}>
          <InlineSearchCriteriaPanel
            tlDataPrefix={tlDataPrefix}
            searchFunc={getCriteriasFunction}
            onSearch={search}
            defaultCriterias={defaultCriterias}
            overrideListValues={overrideListValues}
            triggerInitialSearch
          />
        </FieldSet>
        <FieldSet title={resultText}>
          <ColumnsContainer>
            <GridSelector
              idField={idField}
              searchObject={availableSearchObject}
              searchFunction={searchFunction}
              selectionChanged={setLeftSelected}
              selectedIds={leftSelected}
              filterChanged={setLeftFilter}
              currentCountChanged={setLeftCount}
              columns={columns}
              sortKeys={sortKeys}
              defaultPageSize={defaultPageSize}
              defaultAvailablePageSizes={defaultAvailablePageSizes}
            />
            <ButtonsColumn>
              <Button
                loading={addAllLoading}
                intent={Intent.PRIMARY}
                icon={IconNames.DOUBLE_CHEVRON_RIGHT}
                onClick={addAll}
                disabled={leftCount === 0}
              />
              <ToolTipButton
                disabled={leftSelected.length === 0}
                tooltip={addElementTextFunc(leftSelected.length)}
                intent={Intent.PRIMARY}
                icon={IconNames.CHEVRON_RIGHT}
                onClick={addSelected}
              />
              <ToolTipButton
                disabled={rightSelected.length === 0}
                tooltip={removeElementTextFunc(rightSelected.length)}
                intent={Intent.PRIMARY}
                icon={IconNames.CHEVRON_LEFT}
                onClick={removeSelected}
              />
              <Button
                disabled={rightCount === 0}
                loading={removeAllLoading}
                intent={Intent.PRIMARY}
                icon={IconNames.DOUBLE_CHEVRON_LEFT}
                onClick={removeAll}
              />
            </ButtonsColumn>
            <GridSelector
              idField={idField}
              searchObject={selectedSearchObject}
              searchFunction={searchFunction}
              selectionChanged={setRightSelected}
              selectedIds={rightSelected}
              filterChanged={setRightFilter}
              currentCountChanged={setRightCount}
              columns={columns}
              sortKeys={sortKeys}
              defaultPageSize={defaultPageSize}
              defaultAvailablePageSizes={defaultAvailablePageSizes}
            />
          </ColumnsContainer>
        </FieldSet>
      </BodyContainer>
      <InlineButtonContainer>
        <ButtonsBloc />
        <ButtonsBloc>
          <Button intent={Intent.NONE} icon={cancelIcon} onClick={() => onCancel()}>
            {cancelText}
          </Button>
          <Button intent={Intent.PRIMARY} icon={saveIcon} onClick={() => onSave(linkedItems)}>
            {saveText}
          </Button>
        </ButtonsBloc>
      </InlineButtonContainer>
    </>
  );
}
