import { Classes, Colors } from "@blueprintjs/core";
import {
  DataTable,
  IDataTableColumn,
  ISearchCriteriaValues,
  SortType,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";
import { IInlineSearchCriteriaPanelProps } from "./MasseSelector";

interface IGridSelectorProps<TResults>
  extends Pick<IInlineSearchCriteriaPanelProps, "defaultCriterias" | "overrideListValues" | "tlDataPrefix"> {
  idField: string;
  searchFunction: (sObj: ISearchCriteriaValues) => TResults | Promise<TResults>;
  columns: Array<IDataTableColumn>;
  sortKeys?: {
    [key: string]: SortType;
  };
  searchObject: any;
  selectionChanged: (ids: any[]) => void;
  selectedIds: any[];
  filterChanged: (filter: string) => void;
  currentCountChanged: (count: number) => void;
  defaultPageSize?: number;
  defaultAvailablePageSizes?: number[];
}

export function GridSelector<TResults>(props: IGridSelectorProps<TResults>) {
  const {
    idField,
    searchFunction,
    columns,
    sortKeys,
    searchObject,
    selectionChanged,
    selectedIds,
    filterChanged,
    currentCountChanged,
    defaultPageSize = 10,
    defaultAvailablePageSizes = [10, 25, 50]
  } = props;

  const tableState = useGridState<any>({
    serverMode: true,
    pageSize: defaultPageSize,
    availablePageSizes: defaultAvailablePageSizes,
    sortKeys: sortKeys,
    enablePagination: true,
    enableFilter: true
  });

  const [finalSearch, setFinalSearch] = React.useState<ISearchCriteriaValues>(searchObject);

  const finalSearchFunction = React.useCallback((sObj: ISearchCriteriaValues) => searchFunction(sObj), [
    searchFunction
  ]);

  const { search, loading } = useSearchApi({
    searchFunction: finalSearchFunction,
    tableState,
    initialSearch: false
  });

  React.useEffect(() => {
    search(finalSearch);
  }, [finalSearch, search]);

  React.useEffect(() => {
    setFinalSearch(searchObject);
  }, [searchObject]);

  const selectRow = React.useCallback(
    (item: any) => {
      if (selectedIds.find(d => d === item[idField])) {
        selectionChanged(selectedIds.filter(d => d !== item[idField]));
      } else {
        selectionChanged(selectedIds.concat([item[idField]]));
      }
    },
    [idField, selectedIds, selectionChanged]
  );

  const { globalFilter, totalCount } = tableState;

  React.useEffect(() => filterChanged(globalFilter), [filterChanged, globalFilter]);
  React.useEffect(() => currentCountChanged(totalCount), [currentCountChanged, totalCount]);

  const customizeRowStyleCallback = React.useCallback(
    (d: any) => {
      const isSelected = selectedIds.includes(d[idField]);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & td {
          vertical-align: middle !important;

          & .${Classes.CONTROL} {
            margin: 0;
          }
        }
        background-color: ${isSelected ? Colors.BLUE1 : "transparent"};
      `;
    },
    [idField, selectedIds]
  );

  return (
    <DataTable
      tableState={tableState}
      columns={columns}
      loading={loading}
      onRowClick={selectRow}
      customizeRowStyle={customizeRowStyleCallback}
    />
  );
}
