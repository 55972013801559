export const versions = {
  version: "1.0.183",
  revision: "096382a3b",
  branch: "HEAD",
  gitInfo: {
    dirty: true,
    raw: "v1.0.183-4840-g096382a3b-dirty",
    hash: "g096382a3b",
    distance: 4840,
    tag: "v1.0.183",
    semver: {
      options: { loose: false, includePrerelease: false },
      loose: false,
      raw: "v1.0.183",
      major: 1,
      minor: 0,
      patch: 183,
      prerelease: [],
      build: [],
      version: "1.0.183"
    },
    suffix: "4840-g096382a3b-dirty",
    semverString: "1.0.183+4840.g096382a3b.dirty"
  }
};
