import { IOptionProps } from "@blueprintjs/core";
import { FormikProps } from "formik";
import { FGCustomPanel, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import {
  ApprenantApi,
  ApprenantRepresentantHopeApi,
  ApprenantRepresentantInstitutionEditDto,
  ApprenantRepresentantInstitutionEditDtoFromJSON,
  InstitutionApi,
  RepresentantApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CommuneSelect,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGWalterCheckboxInput,
  FGWalterSelectInput,
  InstitutionSelectorField,
  LocaliteSelect,
  RepresentantSelectorField,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantRepresentantInstitutionDetailProps {
  onTypeRepresentantChange: (typeAcquis: string) => void;
}

export const ApprenantRepresentantInstitutionDetail: React.FunctionComponent<IApprenantRepresentantInstitutionDetailProps> = ({
  onTypeRepresentantChange
}) => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const [typeRepresentant, trLoading] = useReferential(a => a.referentialGetRepresentantTypes(), false, []);
  const mappedTypeRepresentant = React.useMemo(
    () => typeRepresentant?.map(l => ({ ...l, label: tUnsafe(l.label) } as IOptionProps)) ?? [],
    [typeRepresentant, tUnsafe]
  );
  const formikInnerRef = React.useRef<FormikProps<ApprenantRepresentantInstitutionEditDto>>();
  const { id, state, subId } = useParams<{
    id: string;
    tab: string;
    state: string;
    subId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);
  const api = useApiService(ApprenantRepresentantHopeApi);
  const institutionApi = useApiService(InstitutionApi);
  const representantApi = useApiService(RepresentantApi);
  const apprenantApi = useApiService(ApprenantApi);
  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return +subId <= 0
            ? ApprenantRepresentantInstitutionEditDtoFromJSON({
                idapprenant: +id,
                type: "Institution"
              })
            : api.apprenantRepresentantHopeGetApprenantRepresentantInstitution({
                idapprenant: +id,
                idapprenantInstitution: +subId
              });
        },
        saveApiFn: d =>
          api.apprenantRepresentantHopeSaveApprenantRepresentantInstitution({
            ApprenantRepresentantInstitutionEditDto: d
          }),
        onSavedRoute: d => `${ERoutes.apprenant}/${id}/representants`,
        getDeps: [subId],
        deleteApiFn: d =>
          api.apprenantRepresentantHopeDeleteApprenantRepresentantInstitution({
            idapprenantInstitution: d.idapprenantInstitution,
            idapprenant: d.idapprenant
          }),
        onDeletedRoute: () => `${ERoutes.apprenant}/${id}/representants`
      }),
      [subId, id, api]
    )
  );

  const [institutionIdFromSelect, setInstitutionIdFromSelect] = React.useState(0);
  const [previousIdinstitution, setPreviousIdinstitution] = React.useState(data?.idinstitution);
  React.useEffect(() => {
    if (data?.idinstitution) {
      setPreviousIdinstitution(data?.idinstitution);
      setInstitutionIdFromSelect(data?.idinstitution);
    }
  }, [data?.idinstitution]);
  const fetchInstitutiontId = React.useCallback(async () => {
    if (!institutionIdFromSelect || institutionIdFromSelect === 0) return null;
    return await institutionApi.institutionGetInstitution({ id: institutionIdFromSelect });
  }, [institutionApi, institutionIdFromSelect]);

  const { data: representantInstitution } = useQuery(
    ["representant-for-select", institutionIdFromSelect],
    fetchInstitutiontId,
    { enabled: true }
  );
  React.useEffect(() => {
    if (
      institutionIdFromSelect !== 0 &&
      institutionIdFromSelect !== previousIdinstitution &&
      !!representantInstitution &&
      !loading
    ) {
      formikInnerRef.current?.setFieldValue("adresseInstitution", representantInstitution?.adresse);
      formikInnerRef.current?.setFieldValue("localiteInstitution", representantInstitution?.localite);
      formikInnerRef.current?.setFieldValue("communeInstitution", representantInstitution?.localite);
      formikInnerRef.current?.setFieldValue("gsmInstitution", representantInstitution?.gsm);
      formikInnerRef.current?.setFieldValue("telephoneInstitution", representantInstitution?.telephone);
      formikInnerRef.current?.setFieldValue("emailInstitution", representantInstitution?.email);
      setPreviousIdinstitution(institutionIdFromSelect);
    }
  }, [institutionIdFromSelect, loading, previousIdinstitution, representantInstitution]);

  const [representantIdFromSelect, setRepresentantIdFromSelect] = React.useState(0);
  const [previousIdrepresentant, setPreviousIdrepresentant] = React.useState(data?.idrepresentant);
  React.useEffect(() => {
    if (data?.idrepresentant) {
      setPreviousIdrepresentant(data?.idrepresentant);
      setRepresentantIdFromSelect(data?.idrepresentant);
    }
  }, [data?.idrepresentant]);
  const fetchRepresentantId = React.useCallback(async () => {
    if (!representantIdFromSelect || representantIdFromSelect === 0) return null;
    return await representantApi.representantGet({ id: representantIdFromSelect });
  }, [representantApi, representantIdFromSelect]);

  const { data: representantPersonne } = useQuery(
    ["representant-for-select", representantIdFromSelect],
    fetchRepresentantId,
    { enabled: true }
  );

  React.useEffect(() => {
    if (
      representantIdFromSelect !== 0 &&
      representantIdFromSelect !== previousIdrepresentant &&
      !loading &&
      !!representantPersonne
    ) {
      formikInnerRef.current?.setFieldValue("idgsmPersonne", representantPersonne?.gsmRepresentant?.idtelephone);
      formikInnerRef.current?.setFieldValue(
        "idtelephonePersonne",
        representantPersonne?.telephoneRepresentant?.idtelephone
      );
      formikInnerRef.current?.setFieldValue("idemailPersonne", representantPersonne?.emailRepresentant?.idemail);
      setPreviousIdrepresentant(representantIdFromSelect);
    }
  }, [loading, previousIdrepresentant, representantIdFromSelect, representantPersonne]);

  const { data: alreadyContact } = useQuery(
    ["alreadyContact-check"],
    () => {
      return api.apprenantRepresentantHopeAlreadyHasEmergencyContactQuery({
        idapprenant: +id,
        idapprenantInstitution: +subId
      });
    },
    { enabled: true }
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        type: Yup.string().required(t(ETLCodes.Required)),
        idinstitution: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        gsmInstitution: Yup.string()
          .nullable()
          .when(["telephoneInstitution", "emailInstitution"], {
            is: (telephoneInstitution, emailInstitution) => !telephoneInstitution && !emailInstitution,
            then: Yup.string()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        telephoneInstitution: Yup.string()
          .nullable()
          .when(["emailInstitution", "gsmInstitution"], {
            is: (gsmInstitution, emailInstitution) => !gsmInstitution && !emailInstitution,
            then: Yup.string()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        emailInstitution: Yup.string()
          .nullable()
          .when(["telephoneInstitution", "gsmInstitution"], {
            is: (gsmInstitution, telephoneInstitution) => !gsmInstitution && !telephoneInstitution,
            then: Yup.string()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        idgsmPersonne: Yup.number()
          .nullable()
          .when(["idtelephonePersonne", "idemailPersonne", "idrepresentant"], {
            is: (idtelephonePersonne, idemailPersonne, idrepresentant) =>
              !!idrepresentant && !idtelephonePersonne && !idemailPersonne,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        idtelephonePersonne: Yup.number()
          .nullable()
          .when(["idemailPersonne", "idgsmPersonne", "idrepresentant"], {
            is: (idgsmPersonne, idemailPersonne, idrepresentant) =>
              !!idrepresentant && !idgsmPersonne && !idemailPersonne,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        idemailPersonne: Yup.number()
          .nullable()
          .when(["idtelephonePersonne", "idgsmPersonne", "idrepresentant"], {
            is: (idgsmPersonne, idtelephonePersonne, idrepresentant) =>
              !!idrepresentant && !idgsmPersonne && !idtelephonePersonne,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          })
      },
      [
        ["gsmInstitution", "telephoneInstitution"],
        ["gsmInstitution", "emailInstitution"],
        ["telephoneInstitution", "emailInstitution"],
        ["idgsmPersonne", "idtelephonePersonne"],
        ["idgsmPersonne", "idemailPersonne"],
        ["idtelephonePersonne", "idemailPersonne"]
      ]
    );
  }, [t]);

  const fetchIdPersonne = React.useCallback(async () => {
    if (!+(representantIdFromSelect ?? 0)) return 0;

    return await representantApi.representantGetIdpersonne({ idrepresentant: representantIdFromSelect });
  }, [representantApi, representantIdFromSelect]);
  const { data: idpersonne } = useQuery(
    ["apprenant-representant-idpersonne", representantIdFromSelect],
    fetchIdPersonne
  );

  const fetchAlreadySelectedinstitutionIds = React.useCallback(async () => {
    if (+id <= 0) return [];

    return await apprenantApi.apprenantGetAlreadySelectedInstituttionIds({ idapprenant: +id });
  }, [apprenantApi, id]);
  const { data: alreadySelectedInstitutionIds } = useQuery(
    ["apprenant-alreadyselected-institutions", id],
    fetchAlreadySelectedinstitutionIds
  );

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${id}/representants`)}
      saving={saving}
      showDeleteButton={+subId > 0}
      onDelete={deleteItem}
      deleting={deleting}
      formikInnerRef={formikInnerRef}
      validationErrors={validationErrors}
      watchChanges={{
        type: onTypeRepresentantChange,
        idinstitution: value => setInstitutionIdFromSelect(value),
        idrepresentant: value => setRepresentantIdFromSelect(value)
      }}
    >
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.Representant)
          }}
        >
          <FGWalterSelectInput
            label={t(ETLCodes.Type)}
            name="type"
            items={mappedTypeRepresentant}
            loading={trLoading}
            readonly={+(subId ?? 0) > 0}
            isCleareable={false}
          />
          <InstitutionSelectorField
            label={t(ETLCodes.Institutions)}
            name="idinstitution"
            parentInstitutionIds={alreadySelectedInstitutionIds ?? []}
          />
          <FGTextInput name="adresseInstitution" label={t(ETLCodes.Adresse)} maxLength={100} />
          <FGTextInput name="codePostalInstitution" label={t(ETLCodes.CodePostal)} maxLength={6} />
          <FGCustomPanel>
            {ctx => (
              <LocaliteSelect
                codePostal={ctx.formik.values.codePostalInstitution}
                name="localiteInstitution"
                codePostalTextName="codePostalInstitution"
                disableIfNoCp={false}
              />
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <CommuneSelect
                codePostal={ctx.formik.values.codePostalInstitution}
                name="communeInstitution"
                enableAutoSelect={
                  ctx.formik.initialValues.codePostalInstitution !== ctx.formik.values.codePostalInstitution
                }
              />
            )}
          </FGCustomPanel>
          <FGTextInput name="gsmInstitution" label={t(ETLCodes.Gsm)} maxLength={20} />
          <FGTextInput name="telephoneInstitution" label={t(ETLCodes.Tel)} maxLength={20} />
          <FGTextInput name="emailInstitution" label={t(ETLCodes.General_Email)} maxLength={100} />

          <RepresentantSelectorField label={t(ETLCodes.PersonneContact)} name="idrepresentant" />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGEditableTelSelectInput
                  name="idgsmPersonne"
                  label={t(ETLCodes.Gsm)}
                  autoSelectIfOne={+subId <= 0}
                  visible={!!ctx?.formik?.values?.idrepresentant}
                  idpersonne={idpersonne}
                  types={["GSM_PERSO", "GSM_PRO"]}
                />
                <FGEditableTelSelectInput
                  name="idtelephonePersonne"
                  label={t(ETLCodes.Tel)}
                  autoSelectIfOne={+subId <= 0}
                  visible={!!ctx?.formik?.values?.idrepresentant}
                  idpersonne={idpersonne}
                  types={["PERSO", "PRO"]}
                />
                <FGEditableEmailSelectInput
                  name="idemailPersonne"
                  label={t(ETLCodes.General_Email)}
                  autoSelectIfOne={+subId <= 0}
                  visible={!!ctx?.formik?.values?.idrepresentant}
                  idpersonne={idpersonne}
                />
                <FGWalterCheckboxInput
                  label={t(ETLCodes.EmergencyContact)}
                  name="isEmergencyContact"
                  disabled={
                    (!ctx?.formik?.values?.idrepresentant ||
                      ctx?.formik?.values?.alreadyHasEmergencyContact ||
                      alreadyContact?.value) &&
                    !ctx?.formik?.values?.isEmergencyContact
                  }
                  helperText={
                    (ctx?.formik?.values?.alreadyHasEmergencyContact || alreadyContact?.value) &&
                    !ctx?.formik?.values?.isEmergencyContact &&
                    t(ETLCodes.AlreadyHasEmergencyContact)
                  }
                />
                <FGWalterCheckboxInput
                  label={t(ETLCodes.RepresentantLegal)}
                  name="representantLegal"
                  disabled={!ctx?.formik?.values?.idrepresentant}
                />
              </>
            )}
          </FGCustomPanel>

          <FGWalterCheckboxInput label={t(ETLCodes.EnvoiCourrier)} name="courrier" />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
