import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useAuth } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IEditButtonProps extends ICustomButtonProps {
  specificRights?: { module: string; tab?: string };
}

export const EditButton: React.FunctionComponent<IEditButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text,
  tabIndex,
  specificRights,
  small
}) => {
  const { t } = useTl();
  const { getCurrentPermission, hasPermission } = useAuth();
  const hasRights = React.useMemo(
    () =>
      !!specificRights
        ? hasPermission(specificRights.module, "R", specificRights.tab)
        : getCurrentPermission()?.permission?.includes("RW"),
    [getCurrentPermission, hasPermission, specificRights]
  );

  return hasRights ? (
    <Button
      icon={IconNames.EDIT}
      minimal={minimal}
      onClick={onClick}
      text={text ? text : minimal ? null : t(ETLCodes.Modify)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      tabIndex={tabIndex}
      small={small}
    />
  ) : (
    <></>
  );
};
