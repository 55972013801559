import { FormikProps } from "formik";
import { isEqual } from "lodash";
import {
  FGCustomPanel,
  FGEmpty,
  FGListen,
  FGMaskInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext,
  useDebouncedCallback
} from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import * as Yup from "yup";

import {
  AdresseDtoFromJSON,
  FcbRepresentantDto,
  FcbRepresentantDtoFromJSON,
  RepresentantApi,
  RepresentantDoublonSearch
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AdresseDoublonErrorLink,
  CommuneSelect,
  FGEditableEmailSelectInput,
  FGEditableTelSelectInput,
  FGPersonneCodePostal,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LocaliteSelect,
  NationaliteSelect,
  SmallFormGenerator,
  StyledError
} from "../../../../components";
import FGCopyTextInput from "../../../../components/formGenerator/FGCopyTextInput";
import { useBeIdCardContext, useDialog } from "../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { belgianIdValidate } from "../../../../utils/belgianIdValidate";
import { phoneRegex } from "../../../../utils/phoneRegex";
import pick from "lodash/pick";

export interface IRepresentantDetailPageProps {
  baseData: RepresentantDoublonSearch;
}

export const RepresentantDetailPage: React.FunctionComponent<IRepresentantDetailPageProps> = ({ baseData }) => {
  const { t } = useTl();
  const api = useApiService(RepresentantApi);
  const history = useHistory();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const { card } = useBeIdCardContext();
  const genderMapping = React.useMemo(() => ({ M: "H", F: "F", V: "F", W: "F" }), []);
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);
  const { theme } = useTheme();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi<FcbRepresentantDto>(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname,
              prenom: card?.firstName,
              dateNaissance: card?.dateOfBirthParsed,
              nationalite: baseData?.nationalite,
              numeroNational: card?.nationalNumber,
              adresseNaissance: card?.locationOfBirth,
              codesexe: card?.gender ? genderMapping[card?.gender] : null,
              adresseRepresentant: !!card
                ? AdresseDtoFromJSON({
                    adresse: card?.addressStreetAndNumber,
                    codePostalText: card?.addressZip
                  })
                : null
            };
          }
          return +id <= 0
            ? FcbRepresentantDtoFromJSON({
                ...newData,
                idRepresentant: 0
              })
            : api.representantGet({ id: +id });
        },
        saveApiFn: d => api.representantSave({ FcbRepresentantDto: d }),
        onSaved: d => sendMessage(d.idrepresentant, true),
        onSavedRoute: d => `${ERoutes.representant}`,
        deleteApiFn: d => api.representantDelete({ id: d.idrepresentant }),
        onDeletedRoute: () => ERoutes.representant
      }),
      [api, baseData, card, genderMapping, id, sendMessage]
    )
  );

  const [sexe, loadingSexe] = useReferential(a => a.referentialGetSexe(), false, []);
  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);
  const [hasDoublons, setHasDoublons] = React.useState(false);
  const checkDoublons = React.useCallback(
    async values => {
      const res = await api.representantCheckDoublons({ FcbRepresentantDto: values });
      setHasDoublons(!res.isValid);
    },
    [api]
  );
  const debouncedCheckDoublons = useDebouncedCallback(checkDoublons, 1000);

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.RepresentantIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  const canUseCardData = React.useMemo(() => {
    if (+id > 0) {
      return !data?.numeroNational || data?.numeroNational === card?.nationalNumber;
    }

    return false;
  }, [card?.nationalNumber, data?.numeroNational, id]);

  React.useEffect(() => {
    if (data && card) {
      const cardObj = FcbRepresentantDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        dateNaissance: card?.dateOfBirthParsed,
        numeroNational: card?.nationalNumber,
        adresseNaissance: card?.locationOfBirth,
        photo: card?.photoB64,
        codesexe: card?.gender ? genderMapping[card?.gender] : null,
        adresse: card?.addressStreetAndNumber,
        codePostal: card?.addressZip
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational", "adresseNaissannce", "codesexe"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        !isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) ||
        cardBirthdateString !== dataBirthdateString
      ) {
        if (canUseCardData) {
          askDataDifferent();
        }
      }
    }
  }, [askDataDifferent, canUseCardData, card, data, genderMapping, t]);

  const formikRef = React.useRef<FormikProps<FcbRepresentantDto>>();

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      formikRef?.current?.setValues(
        FcbRepresentantDtoFromJSON({
          ...data,
          nom: card?.surname ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          nationalite: data.nationalite ?? "BE",
          numeroNational: card?.nationalNumber ?? data.numeroNational,
          adresseNaissance: card?.locationOfBirth,
          codesexe: card?.gender ? genderMapping[card?.gender] : data.codesexe,
          adresseRepresentant: AdresseDtoFromJSON({
            adresse: card?.addressStreetAndNumber,
            codePostalText: card?.addressZip
          })
        }),
        true
      );
    }
  }, [
    card?.addressStreetAndNumber,
    card?.addressZip,
    card?.cardNumber,
    card?.dateOfBirthParsed,
    card?.firstName,
    card?.gender,
    card?.locationOfBirth,
    card?.nationalNumber,
    card?.photoB64,
    card?.surname,
    cardDataDifferent,
    data,
    genderMapping,
    saveItem,
    formikRef,
    card?.nationality
  ]);

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      prenom: Yup.string().required(t(ETLCodes.Required)),
      adresseRepresentant: Yup.object().shape(
        {
          adresse: Yup.string().test("adresse-required", t(ETLCodes.AtLeastOneRepresentantField), function(
            value,
            context: any
          ) {
            if (this.parent.idrepresentant > 0 || !!context.from[1].value.idpersonne) {
              return (
                !!value ||
                !!context.from[1].value.telephoneRepresentant?.idtelephone ||
                !!context.from[1].value.telephoneProRepresentant?.idtelephone ||
                !!context.from[1].value.gsmRepresentant?.idtelephone ||
                !!context.from[1].value.emailRepresentant?.idemail
              );
            }
            if ((!this.parent.idrepresentant || this.parent.idrepresentant <= 0) && !context.from[1].value.idpersonne) {
              return (
                !!value ||
                !!context.from[1].value.telephoneRepresentant?.numero ||
                !!context.from[1].value.telephoneProRepresentant?.numero ||
                !!context.from[1].value.gsmRepresentant?.numero ||
                !!context.from[1].value.emailRepresentant?.adresseEmail
              );
            }
          }),
          pays: Yup.string()
            .nullable()
            .when("adresse", {
              is: adresse => !!adresse,
              then: schema => schema.required(t(ETLCodes.Required))
            })
        },
        [["adresse", "pays"]]
      ),
      telephoneRepresentant: Yup.object().shape({
        idtelephone: Yup.number()
          .nullable()
          .test("telid-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if ((!this.parent.idrepresentant || this.parent.idrepresentant <= 0) && !context.from[1].value.idpersonne)
              return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneProRepresentant?.idtelephone ||
              !!context.from[1].value.gsmRepresentant?.idtelephone ||
              !!context.from[1].value.emailRepresentant?.idemail
            );
          }),
        numero: Yup.string()
          .nullable()
          .test("tel-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !!context.from[1].value.idpersonne) return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneProRepresentant?.numero ||
              !!context.from[1].value.gsmRepresentant?.numero ||
              !!context.from[1].value.emailRepresentant?.adresseEmail
            );
          })
          .test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !value || !!context.from[1].value.idpersonne) return true;
            const numero = value as string;
            return !!numero.match(phoneRegex);
          })
      }),
      telephoneProRepresentant: Yup.object().shape({
        idtelephone: Yup.number()
          .nullable()
          .test("telid-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if ((!this.parent.idrepresentant || this.parent.idrepresentant <= 0) && !context.from[1].value.idpersonne)
              return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.idtelephone ||
              !!context.from[1].value.gsmRepresentant?.idtelephone ||
              !!context.from[1].value.emailRepresentant?.idemail
            );
          }),
        numero: Yup.string()
          .nullable()
          .test("tel-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !!context.from[1].value.idpersonne) return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.numero ||
              !!context.from[1].value.gsmRepresentant?.numero ||
              !!context.from[1].value.emailRepresentant?.adresseEmail
            );
          })
          .test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !value || !!context.from[1].value.idpersonne) return true;
            const numero = value as string;
            return !!numero.match(phoneRegex);
          })
      }),
      gsmRepresentant: Yup.object().shape({
        idtelephone: Yup.number()
          .nullable()
          .test("gsmid-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if ((!this.parent.idrepresentant || this.parent.idrepresentant <= 0) && !context.from[1].value.idpersonne)
              return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.idtelephone ||
              !!context.from[1].value.telephoneProRepresentant?.idtelephone ||
              !!context.from[1].value.emailRepresentant?.idemail
            );
          }),
        numero: Yup.string()
          .nullable()
          .test("gsm-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !!context.from[1].value.idpersonne) return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.numero ||
              !!context.from[1].value.telephoneProRepresentant?.numero ||
              !!context.from[1].value.emailRepresentant?.adresseEmail
            );
          })
          .test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !value || !!context.from[1].value.idpersonne) return true;
            const numero = value as string;
            return !!numero.match(phoneRegex);
          })
      }),
      emailRepresentant: Yup.object().shape({
        idemail: Yup.number()
          .nullable()
          .test("emailid-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if ((!this.parent.idrepresentant || this.parent.idrepresentant <= 0) && !context.from[1].value.idpersonne)
              return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.idtelephone ||
              !!context.from[1].value.telephoneProRepresentant?.idtelephone ||
              !!context.from[1].value.gsmRepresentant?.idtelephone
            );
          }),
        adresseEmail: Yup.string()
          .nullable()
          .email(t(ETLCodes.InvalidEmail))
          .test("gsm-required", t(ETLCodes.AtLeastOneRepresentantField), function(value, context: any) {
            if (this.parent.idrepresentant > 0 || !!context.from[1].value.idpersonne) return true;
            return (
              !!value ||
              !!context.from[1].value.adresseRepresentant?.adresse ||
              !!context.from[1].value.telephoneRepresentant?.numero ||
              !!context.from[1].value.telephoneProRepresentant?.numero ||
              !!context.from[1].value.gsmRepresentant?.numero
            );
          })
      }),
      codesexe: Yup.string().required(t(ETLCodes.Required)),
      nationalite: Yup.string().nullable(),
      dateNaissance: Yup.date().nullable(),
      numeroNational: Yup.string()
        // .when("nationalite", (value, schema) =>
        //   value && value === "BE" ? schema.required(t(ETLCodes.Required)) : schema
        // )
        .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
          if (!value || value === "") return true;

          var millenial = false;
          if (this.parent.dateNaissance) {
            const parsedDateNaissance = new Date(this.parent.dateNaissance);
            if (parsedDateNaissance.getFullYear() > 1999) {
              millenial = true;
            }
          }

          return belgianIdValidate(value, millenial);
        })
    });
  }, [t]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.numeroNational?.replace(".", "")?.replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.numeroNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const isFullCreation = React.useMemo(() => +id <= 0 && !data?.idpersonne, [data?.idpersonne, id]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={state === "edit"}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.representant)}
        onDelete={deleteItem}
        showDeleteButton={+id > 0}
        saving={saving}
        deleting={deleting}
        formikInnerRef={i => (formikRef.current = i)}
        forceEnableSave={Object.values(baseData).some(el => !!el)}
      >
        <FGListen field="nom" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
        <FGListen field="prenom" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
        <FGListen field="dateNaissance" onChanged={(value, formik) => debouncedCheckDoublons(formik?.values)} />
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <NationaliteSelect
            name="nationalite"
            codeSexeFieldName="codesexe"
            helperText={card && `${t(ETLCodes.NationaliteSurCarte)}: ${card?.nationality}`}
          />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          <FGWalterSelectInput name="codesexe" label={t(ETLCodes.Genre)} items={sexe} loading={loadingSexe} />
          <FGTextInput name="adresseNaissance" label={t(ETLCodes.LieuNaissance)} maxLength={100} />
          <FGEmpty />
          <FGMaskInput
            name="numeroNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
            helperText={ctx => (
              <>
                {isRegNatDateNaiss(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                )}
                {isRegNatGenre(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                )}
              </>
            )}
          />
          <FGEmpty />
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
          />
        </FieldGroup>
        <FieldGroup columns={2}>
          {data?.hasAddressDoublons && (
            <>
              <AdresseDoublonErrorLink idpersonne={idpersonne} />
              <FGEmpty />
            </>
          )}
          <FGWalterSelectInput
            name="adresseRepresentant.pays"
            label={t(ETLCodes.Pays)}
            items={pays}
            loading={loadingPays}
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGTextAreaInput
            name="adresseRepresentant.adresse"
            label={t(ETLCodes.Adresse)}
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGPersonneCodePostal
            idName="adresseRepresentant.idcodePostal"
            textName="adresseRepresentant.codePostalText"
            localiteName="adresseRepresentant.localite"
            disabled={data?.hasAddressDoublons}
          />
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <LocaliteSelect
                codePostal={ctx.formik.values?.adresseRepresentant?.codePostalText}
                codePays={ctx.formik.values?.adresseRepresentant?.pays}
                name="adresseRepresentant.localite"
                codePostalTextName="adresseRepresentant.codePostalText"
                disableIfNoCp={false}
                disabled={data?.hasAddressDoublons}
              />
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <CommuneSelect
                codePostal={ctx.formik.values?.adresseRepresentant?.codePostalText}
                codePays={ctx.formik.values?.adresseRepresentant?.pays}
                name="adresseRepresentant.commune"
                enableAutoSelect={
                  ctx.formik.initialValues?.adresseRepresentant?.codePostalText !==
                  ctx.formik.values?.adresseRepresentant?.codePostalText
                }
                disabled={data?.hasAddressDoublons}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup columns={2}>
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="telephoneRepresentant.numero"
              label={t(ETLCodes.Tel)}
              maxLength={20}
            />
          ) : (
            <FGEditableTelSelectInput
              name="telephoneRepresentant.idtelephone"
              label={t(ETLCodes.Tel)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["PERSO"]}
            />
          )}
          <FGEmpty />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="telephoneProRepresentant.numero"
              label={t(ETLCodes.TelPro)}
              maxLength={20}
            />
          ) : (
            <FGEditableTelSelectInput
              name="telephoneRepresentant.idtelephone"
              label={t(ETLCodes.TelPro)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["PRO"]}
            />
          )}
          <FGEmpty />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="gsmRepresentant.numero"
              label={t(ETLCodes.Gsm)}
              maxLength={20}
            />
          ) : (
            <FGEditableTelSelectInput
              name="gsmRepresentant.idtelephone"
              label={t(ETLCodes.Gsm)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
              types={["GSM_PERSO"]}
            />
          )}
          <FGEmpty />
          {isFullCreation ? (
            <FGTextInput name="emailRepresentant.adresseEmail" label={t(ETLCodes.General_Email)} maxLength={100} />
          ) : (
            <FGEditableEmailSelectInput
              name="emailRepresentant.idemail"
              label={t(ETLCodes.General_Email)}
              visible={!isFullCreation}
              autoSelectIfOne
              idpersonne={idpersonne}
            />
          )}
          <FGEmpty />
        </FieldGroup>
        <FGCustomPanel>
          {ctx => <StyledError show={hasDoublons} message={t(ETLCodes.DoublonExistsConfirm)}></StyledError>}
        </FGCustomPanel>
      </SmallFormGenerator>
    )
  );
};
